import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ComponentBase } from '../../shared/component-base';
import { EventBus } from '../../shared/event-bus';
import { Web3Service } from '../../shared/web3-service';
import { DlgContractService } from '../dlg-contract.service';

import { AlertService } from '../shared-dlg.module';
import { UserSessionProvider } from '../../shared/user-session-provider';
import { DealDTO, DealServiceProxy, DealUpdateDTO } from '../../service-proxies/service-proxies';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';

@Component({
  templateUrl: './tiers.component.html',
  styleUrls: ['./tiers.component.scss'],
})
export class TiersPageComponent extends ComponentBase implements OnInit {
  constructor(
    private eventBus: EventBus,
    private web3Service: Web3Service,
    private userSessionProvider: UserSessionProvider,
    private dealService: DealServiceProxy,
    private route: ActivatedRoute
  ) {
    super();
  }

  waiting: boolean = false;
  account: string = '';

  allTiers: any[] = new Array();
  newTierTokenAmount: number;
  newTierTokenOnlyAmount: number;
  newTierTimeLockups: number;
  newTierBaseWeight: number;

  updateTierIndex: number = -1;
  updateTierTokenAmount: number;
  updateTierTokenOnlyAmount: number;
  updateTierTimeLockups: number;
  updateTierBaseWeight: number;

  async ngOnInit() {
    await this.web3Service.initWeb3();

    this.eventBus.loginEvent.subscribe(result => {
      console.log('loginEvent subscription:' + result);
      this.eventLogin(result);
    });

    this.eventBus.logoutEvent.subscribe(result => {
      console.log('logoutEvent subscription:' + result);
      this.eventLogout();
    });

    //this.eventBus.outputNetworkChanged.subscribe(result => {
    //  console.log('outputNetworkChanged subscription:' + result);
    //  this.setToNetworkAndPairTo(result);
    //});

    //this.eventBus.fromPairChanged.subscribe(result => {
    //  console.log('fromPairChanged subscription:' + result);
    //  this.setPairFrom(result);
    //});
    this.account = this.userSessionProvider.linkedWallet;
    this.updateTiersContractData();
  }


  eventLogin(username: string): void {
    console.log('eventLogin');
    console.log(username);
    this.account = username;
  }

  eventLogout(): void {
    this.account = "";
    //this.balance = null;
    //console.log('eventLogout')
    //if (this.timerId) {
    //  console.log('clearInterval');
    //  clearInterval(this.timerId);
    //}
  }


  async updateTiersContractData() {
    console.log('Get contract data')

    this.allTiers = new Array();
    let tiesrLenght = parseInt(await this.web3Service.getDealLockupsTiersLength());
    for (let i = 0; i < tiesrLenght; i++) {
      let tier = await this.web3Service.getDealLockupsTiers(i);
      this.allTiers.push(tier);
    }
  }

  select(index: number) {
    this.updateTierIndex = index;
    this.updateTierTokenAmount = this.toNumberFromWei(this.allTiers[index].tokenAmount, 18);
    this.updateTierTokenOnlyAmount = this.toNumberFromWei(this.allTiers[index].tokenOnlyAmount, 18);
    this.updateTierTimeLockups = this.allTiers[index].timeLockups;
    this.updateTierBaseWeight =  this.allTiers[index].baseWeight;
  }

  async updateTierClick(): Promise<void> {
    this.waiting = true;

    try {
      const contractEventsSource = this.web3Service.dealLockupsUpdateTier(
        this.account, 
        this.updateTierIndex,
        this.updateTierTokenOnlyAmount, // set tokenAmount the same as tokenOnlyAmount
        this.updateTierTokenOnlyAmount, 
        this.updateTierTimeLockups,
        this.updateTierBaseWeight
      );

      await contractEventsSource.receipt$.toPromise();

      this.showInfoModal('Confirmed transaction');
      this.updateTierIndex = -1;
      this.updateTierTokenAmount = null;
      this.updateTierTokenOnlyAmount = null;
      this.updateTierTimeLockups = null;
      this.updateTiersContractData();
    } catch (err) {
      this.showErrorModal(JSON.stringify(err));
      console.info('catch');
      console.info(err);
    }

    this.waiting = false;
  }

  async addTierClick(): Promise<void> {
    this.waiting = true;

    try {
      const contractEventsSource = this.web3Service.dealLockupsAddTier(
        this.account, 
        this.newTierTokenOnlyAmount,   // set tokenAmount the same as tokenOnlyAmount
        this.newTierTokenOnlyAmount, 
        this.newTierTimeLockups, 
        this.newTierBaseWeight
      );

      await contractEventsSource.receipt$.toPromise();

      this.showInfoModal('Confirmed transaction');
      this.updateTiersContractData();
      this.newTierTokenAmount = null;
      this.newTierTokenOnlyAmount = null;
      this.newTierTimeLockups = null;
      this.newTierBaseWeight = null;
    } catch (err) {
      this.showErrorModal(JSON.stringify(err));
      console.info('catch');
      console.info(err);
    }

    this.waiting = false;
  }

}
