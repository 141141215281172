import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateDealComponent } from './create-deal/create-deal.component';
import { EditDealComponent } from './edit-deal/edit-deal.component';
import { UsersComponent } from './users/users.component';
import { DealDetailComponent } from './deal-detail/deal-detail.component';
import { DealsComponent } from './deals/deals.component';
import { MainPageComponent } from './main/main.component';
import { LoginPageComponent } from './login/login.component';
import { AdminsComponent } from './admins/admins.component';
import { TiersPageComponent } from './tiers/tiers.component';
import { CreateWalletDealComponent } from './create-wallet-deal/create-wallet-deal.component';
import { StakingComponent } from './staking/staking.component';
import { FarmingComponent } from './farming/farming.component';
import { PoolDetailComponent } from './pool-detail/pool-detail.component';
import { CreatePoolComponent } from './create-pool/create-pool.component';
import { EditPoolComponent } from './edit-pool/edit-pool.component';
import { PenaltiesComponent } from './penalties/penalties.component';
import { SettingsComponent } from './settings/settings.component';
import { ClaimingComponent } from './claiming/claiming.component';
import { ClaimingDealDetailComponent } from './claiming-deal-detail/claiming-deal-detail.component';
import { CreateClaimingDealComponent } from './create-claiming-deal/create-claiming-deal.component';
import { EditClaimingDealComponent } from './edit-claiming-deal/edit-claiming-deal.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import {UsersWalletComponent} from "./users-wallet/users-wallet.component";
import {SuperAdminGuard} from "../shared/super-admin.guard";
import {UserPollsComponent} from './user-polls/user-polls.component';
import {CreateUserPollComponent} from './create-user-poll/create-user-poll.component';
import {EditUserPollComponent} from "./edit-user-poll/edit-user-poll.component";

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'users',
        component: UsersComponent,
      },
      {
        path: 'user-details',
        component: UserDetailsComponent,
      },
      {
        path: 'create-deal',
        component: CreateDealComponent,
        canActivate: [SuperAdminGuard],
      },
      {
        path: 'create-wallet-deal',
        component: CreateWalletDealComponent,
        canActivate: [SuperAdminGuard],
      },
      {
        path: 'edit-deal',
        component: EditDealComponent,
        canActivate: [SuperAdminGuard],
      },
      {
        path: 'deals',
        component: DealsComponent,
        canActivate: [SuperAdminGuard],
      },
      {
        path: 'deal-detail',
        component: DealDetailComponent,
        canActivate: [SuperAdminGuard],
      },
      {
        path: 'tiers',
        component: TiersPageComponent,
        canActivate: [SuperAdminGuard],
      },
      {
        path: 'login',
        component: LoginPageComponent,
      },
      {
        path: 'admins',
        component: AdminsComponent,
      },
      {
        path: 'staking',
        component: StakingComponent,
        canActivate: [SuperAdminGuard],
      },
      {
        path: 'farming',
        component: FarmingComponent,
        canActivate: [SuperAdminGuard],
      },
      {
        path: 'pool-detail',
        component: PoolDetailComponent,
        canActivate: [SuperAdminGuard],
      },
      {
        path: 'create-pool',
        component: CreatePoolComponent,
        canActivate: [SuperAdminGuard],
      },
      {
        path: 'edit-pool',
        component: EditPoolComponent,
        canActivate: [SuperAdminGuard],
      },
      {
        path: 'penalties',
        component: PenaltiesComponent,
        canActivate: [SuperAdminGuard],
      },
      {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [SuperAdminGuard],
      },
      {
        path: 'claiming',
        component: ClaimingComponent,
        canActivate: [SuperAdminGuard],
      },
      {
        path: 'claiming-deal-detail',
        component: ClaimingDealDetailComponent,
        canActivate: [SuperAdminGuard],
      },
      {
        path: 'edit-claiming-deal',
        component: EditClaimingDealComponent,
        canActivate: [SuperAdminGuard],
      },
      {
        path: 'wallet-approval',
        component: UsersWalletComponent,
        canActivate: [SuperAdminGuard],
      },
      {
        path: 'create-claiming-deal',
        component: CreateClaimingDealComponent,
        canActivate: [SuperAdminGuard],
      },
      {
        path: 'voting',
        component: UserPollsComponent,
        canActivate: [SuperAdminGuard],
      },
      {
        path: 'create-poll',
        component: CreateUserPollComponent,
        canActivate: [SuperAdminGuard],
      },
      {
        path: 'edit-poll',
        component: EditUserPollComponent,
        canActivate: [SuperAdminGuard],
      },
      { path: '**', redirectTo: 'login' },
      //{
      //  path: 'transfer',
      //  component: AppTransferComponent
      //}
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
