import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserSessionProvider } from '../../shared/user-session-provider';
import { Web3Service } from '../../shared/web3-service';

@Component({
  selector: 'dlg-wallet',
  templateUrl: './dlg-wallet.component.html',
  styleUrls: ['./dlg-wallet.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DlgWalletComponent implements OnInit {
  web3ChainId: number;
  constructor(
    public dialogRef: MatDialogRef<DlgWalletComponent>,
    private userSessionProvider: UserSessionProvider,
    private web3Service: Web3Service
  ) {
    this.web3ChainId = web3Service.chainIdNumber;
  }

  account: string;

  ngOnInit(): void {
    this.account = this.userSessionProvider.linkedWallet;
  }
}
