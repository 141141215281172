import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { INotificationDTO, NotificationDTO, NotificationServiceProxy } from 'src/service-proxies/service-proxies';
import { ComponentBase } from 'src/shared/component-base';

@Component({
  selector: 'dlg-notification',
  templateUrl: './dlg-notification.component.html',
  styleUrls: ['./dlg-notification.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DlgNotificationComponent extends ComponentBase implements OnInit {
  waiting: boolean = false;
  notification: INotificationDTO = new NotificationDTO();

  title: string;
  text: string;
  linkURL: string;
  iconURL: string;

  constructor(public dialogRef: MatDialogRef<DlgNotificationComponent>, 
              private notificationService: NotificationServiceProxy
              ) {
    super();
  }


  ngOnInit(): void {
    
  }

  async submitClick2(): Promise<void> {
    this.waiting = true;
    this.notificationService.add(this.notification)
        .subscribe(
          result => {
            this.waiting = false;
            this.dialogRef.close();
            this.showInfoModal('Notification added');
          },
          error => {
            this.waiting = false;
            console.log(error);
            this.showErrorModal(error.error?.title || error.message);
          }
        );
  }
}
