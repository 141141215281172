import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "dealTypeName"
})
export class DealTypeNamePipe implements PipeTransform {
  transform(value: number): string {
    if (value == 0)
      return "VC"
    else if (value == 1)
      return "IDO"
    else if (value == 2)
      return "INO"
    return value.toString();
  }
}
