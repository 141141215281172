import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "blockExplorerAddressPipe"
})
export class BlockExplorerAddressPipe implements PipeTransform {
  transform(tx: string, chainId: number): string {
    if (chainId == 1)
      return `https://etherscan.io/address/${tx}`;
    if (chainId == 42)
      return `https://kovan.etherscan.io/address/${tx}`;
    if (chainId == 56)
      return `https://bscscan.com/address/${tx}`;
    if (chainId == 97)
      return `https://testnet.bscscan.com/address/${tx}`;
    if (chainId == 256)
      return `https://testnet.hecoinfo.com/address/${tx}`;
    if (chainId == 128)
      return `https://hecoinfo.com/address/${tx}`;
    return "";
  }
}
