import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: './dlg-address-book.component.html',
})

export class DlgAddressBookComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DlgAddressBookComponent>,
  ) {
  }
  @Input() emissionAddress: string;

  async ngOnInit(): Promise<void> {
  }
}
