<main class="dashboard-main">
  <section class="__users-table">

    <table class="table table-responsive">
      <thead>
        <tr>
          <th>Address</th>
          <th>Super Admin</th>
        </tr>
      </thead>
      <tbody>
        <!-- ethAddress!: string | undefined;
          isSuperAdmin!: boolean;-->
        <tr *ngFor="let item of users; index as i">
          <td class="td-responsive td-responsive-full" data-title="Address">
            <div class="__address">{{item.ethAddress}}</div>
          </td>
          <td class="td-responsive td-responsive-full" data-title="Super Admin">
            <span *ngIf="item.isSuperAdmin" class="__admin-status --passed">
              <i class="icomoon-checked"></i>
              Yes
            </span>
            <span *ngIf="!item.isSuperAdmin" class="__admin-status">
              <i class="icomoon-close-fill"></i>
              No
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</main>
