<div class="modal-header">
  <h5 class="modal-title" *ngIf="isDepositMode">Deposit to Pool</h5>
  <h5 class="modal-title" *ngIf="!isDepositMode">Withdraw from Pool</h5>
  <div type="button" class="btn-close" [mat-dialog-close]="true"></div>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-lg-6 mb-4">
      <div class="subtitle-2 text-muted">Your {{item.stakingTokenSymbol}}</div>
      <div class="subtitle-2">{{balance | number: '1.0-2'}} {{item.stakingTokenSymbol}}</div>
    </div>
    <div class="col-lg-6 mb-4">
      <div class="subtitle-2 text-muted">Deposited Tokens</div>
      <div class="subtitle-2">{{currentStakeAmount| shiftDecimals:-stakingTokenDecimals| number: '1.0-2'}} {{item.stakingTokenSymbol}}</div>
    </div>
  </div>

  <div class="d-md-flex align-items-center mb-4" *ngIf="isDepositMode">
    <mat-form-field appearance="fill" hideRequiredMarker class="icon-form-field">
      <img src="../../assets/images/tokens/blp.svg" alt="">
      <mat-label>{{item.stakingTokenSymbol}}</mat-label>
      <input matInput
             type="number"
             min="0"
             max="{{balance | number: '1.0-2'}}"
             name="selectedAmount"
             [(ngModel)]="depositAmount"
             [disabled]="waiting || isApprovedToken"
             required>
      <button mat-button color="primary" class="max-btn" (click)="setMaxDepositAmount()" [disabled]="waiting || isApprovedToken">MAX</button>
      <mat-hint class="text-right">Available {{balance | number: '1.0-2'}}</mat-hint>
    </mat-form-field>
  </div>

  <div class="d-md-flex align-items-center mb-4" *ngIf="!isDepositMode">
    <mat-form-field appearance="fill" hideRequiredMarker class="icon-form-field">
      <img src="../../assets/images/tokens/blp.svg" alt="">
      <mat-label>{{item.stakingTokenSymbol}}</mat-label>
      <input matInput
             type="number"
             min="0"
             name="selectedAmount"
             [(ngModel)]="withdrawAmount"
             [disabled]="waiting || isApprovedToken"
             required>
      <button mat-button color="primary" class="max-btn" (click)="setMaxWithdrawAmount()" [disabled]="waiting || isApprovedToken">MAX</button>
      <mat-hint class="text-right">Available: {{currentStakeAmount| shiftDecimals:-stakingTokenDecimals| number: '1.0-2'}}</mat-hint>
    </mat-form-field>
  </div>

  <div class="mb-4" *ngIf="currentPenalty > 0">
    <div class="subtitle-2 text-muted">Penalty for this withdrawal:</div>
    <div class="subtitle-2">{{currentPenalty}} {{item.stakingTokenSymbol}}</div>

  </div>

  <div class="btn-group-justified">
    <button mat-raised-button
            color="primary"
            class="btn-icon btn-icon-left"
            [ngClass]="{'btn-loading': waiting&&!isApprovedToken}"
            [disabled]="waiting || isApprovedToken"
            (click)="approveClick()">
      <i class="icon icomoon-checkmark"></i>
      Approve
    </button>

    <button mat-raised-button
            color="primary"
            *ngIf="isDepositMode"
            [ngClass]="{'btn-loading': waiting&&isApprovedToken}"
            [disabled]="waiting || !isApprovedToken"
            (click)="depositClick()">
      Deposit {{depositAmount}} {{item.stakingTokenSymbol}}
    </button>

    <button mat-raised-button
            color="primary"
            *ngIf="!isDepositMode"
            [ngClass]="{'btn-loading': waiting&&isApprovedToken}"
            [disabled]="waiting || !isApprovedToken"
            (click)="withdrawClick()">
      Withdraw {{withdrawAmount}} {{item.stakingTokenSymbol}}
    </button>
  </div>
</div>

