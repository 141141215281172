<main class="dashboard-main">
  <section class="dashboard-section p-40" [ngClass]="{'loading-page': waiting}">
    <table class="tiers-table table w-100">
      <thead>
        <tr>
          <th>Tier</th>
          <th>Token Amount</th>
          <th>Base Weight</th>
          <th>Lockup</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of allTiers; index as i;">
        <td data-title="Image" >
          {{ i | nameTierPipe }}
        </td>
        <td data-title="Token amount" [ngClass]="{'edit-opened': i==updateTierIndex}">
          <div [ngClass]="{'mb-3': i==updateTierIndex}">
            {{item.tokenOnlyAmount| shiftDecimals:-18}}
          </div>

          <mat-form-field appearance="fill" *ngIf="i==updateTierIndex">
            <mat-label>Token amount</mat-label>
            <input matInput
                   type="number"
                   [(ngModel)]="updateTierTokenOnlyAmount"
                   name="updateTierTokenOnlyAmount"
                   required>
          </mat-form-field>
        </td>
        <td data-title="Base Weight" [ngClass]="{'edit-opened': i==updateTierIndex}">
          <div [ngClass]="{'mb-3': i==updateTierIndex}">
            {{item.baseWeight}}
          </div>

          <mat-form-field appearance="fill" *ngIf="i==updateTierIndex">
            <mat-label>Base Weight</mat-label>
            <input matInput
                   type="number"
                   [(ngModel)]="updateTierBaseWeight"
                   name="updateTierBaseWeight"
                   required>
          </mat-form-field>
        </td>
        <td data-title="Lockups" [ngClass]="{'edit-opened': i==updateTierIndex}">
          <div [ngClass]="{'mb-3': i==updateTierIndex}">
            {{item.timeLockups}} sec
          </div>

          <mat-form-field appearance="fill" *ngIf="i==updateTierIndex">
            <mat-label>Lockups</mat-label>
            <input matInput
                   type="number"
                   [(ngModel)]="updateTierTimeLockups"
                   name="updateTierTimeLockups"
                   required>
          </mat-form-field>
        </td>
        <td>
          <button *ngIf="i!=updateTierIndex"
                  mat-icon-button
                  [disabled]="waiting"
                  (click)="select(i)"
                  matTooltip="Edit Tier"
                  matTooltipPosition="above">
            <span class="icomoon-edit"></span>
          </button>

          <button *ngIf="i==updateTierIndex"
                  mat-icon-button
                  [disabled]="waiting"
                  (click)="updateTierClick()"
                  matTooltip="Update Tier"
                  matTooltipPosition="above">
            <span class="icomoon-checkmark"></span>
          </button>
        </td>
      </tr>

      <tr>
        <td data-title="Image">
          {{ allTiers.length | nameTierPipe }}
        </td>
        <td class="tiers-table__editable" data-title="Token amount">
          <mat-form-field appearance="fill">
            <mat-label>Token amount</mat-label>
            <input matInput
                   type="number"
                   [(ngModel)]="newTierTokenOnlyAmount"
                   name="newTierTokenOnlyAmount"
                   required>
          </mat-form-field>
        </td>
        <td class="tiers-table__editable" data-title="Base Weight">
          <mat-form-field appearance="fill">
            <mat-label>Base Weight</mat-label>
            <input matInput
                   type="number"
                   [(ngModel)]="newTierBaseWeight"
                   name="newTierBaseWeight"
                   required>
          </mat-form-field>
        </td>
        <td class="tiers-table__editable" data-title="Lockups">
          <mat-form-field appearance="fill">
            <mat-label>Lockups</mat-label>
            <input matInput
                   type="number"
                   [(ngModel)]="newTierTimeLockups"
                   name="newTierTimeLockups"
                   required>
          </mat-form-field>
        </td>
        <td>
          <button
                  mat-icon-button
                  [disabled]="waiting"
                  (click)="addTierClick()"
                  matTooltip="Add Tier"
                  matTooltipPosition="above">
            <span class="icomoon-plus"></span>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </section>
</main>
