<div class="modal-header">
  <svg height="30" viewBox="0 -960 960 960" width="30" fill="#737f9e">
    <path d="M160-200v-60h80v-304q0-84 49.5-150.5T420-798v-22q0-25 17.5-42.5T480-880q25 0 42.5 17.5T540-820v22q81 17 130.5 83.5T720-564v304h80v60H160Zm320-302Zm0 422q-33 0-56.5-23.5T400-160h160q0 33-23.5 56.5T480-80ZM300-260h360v-304q0-75-52.5-127.5T480-744q-75 0-127.5 52.5T300-564v304Z"/>
  </svg>

  <h5 class="modal-title ms-3">Add notification</h5>
  <button class="btn-close" [mat-dialog-close]></button>
</div>

<div class="modal-body stack">
  <div class="row">
    <form #notificationForm="ngForm" (ngSubmit)="submitClick2()">
      <div class="row">
        <div class="col-lg-12 mb-4 mb-lg-0">
          <mat-form-field appearance="fill" class="form-field">
            <mat-label>Title</mat-label>
            <input matInput [(ngModel)]="notification.title" name="title" required>
          </mat-form-field>
        </div>

        <div class="col-lg-12 mb-4 mb-lg-0">
          <mat-form-field appearance="fill" class="form-field">
            <mat-label>Text</mat-label>
            <input matInput [(ngModel)]="notification.text" name="text" required>
          </mat-form-field>
        </div>

        <div class="col-lg-12 mb-4 mb-lg-0">
          <mat-form-field appearance="fill" class="form-field">
            <mat-label>Link URL</mat-label>
            <input matInput [(ngModel)]="notification.linkURL" name="linkURL">
          </mat-form-field>
        </div>

        <div class="col-lg-12 mb-4 mb-lg-0">
          <mat-form-field appearance="fill" class="form-field">
            <mat-label>Icon URL</mat-label>
            <input matInput [(ngModel)]="notification.iconURL" name="iconURL">
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 mt-4 text-right">
          <button type="submit" mat-raised-button color="primary" [ngClass]="{'btn-loading': waiting}"
          [disabled]="!notificationForm.form.valid || waiting">
            Add
          </button>
          <button mat-stroked-button [mat-dialog-close]="true" class="ms-3">
            Cancel
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
