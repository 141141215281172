export const environment = {
  production: false,
  polygon: {
    merkleDistributorCreatorAddress: '0x421d618389d63c0DfDB43Dff1cAD448E52708C9D'
  },
  eth: {
    merkleDistributorCreatorAddress: '0x421d618389d63c0DfDB43Dff1cAD448E52708C9D'
  },
  tokenInfo: {
    address: '0xEC78EC1DD36EE3DbaC429B38D6413a5c5Ee596fe',
    symbol: 'I3',
  },
  bsc: {
    dealCreatorAddress: '0x3DaE99f540dBdEBDB85912d722347c12cb2461Aa',
    dealCollectWalletCreatorAddress: '0x9C986532f1875B381FB4A2690E23Ba0F79E1E679',
    lockerAddress: '0xA30C5EBa7eBeABE670611026505fbB01129003Db',
    dealLockupsAddress: '0x3785f1BEE4E25e5E3fbF7A465F7987DCAbC0D4E9',
    stakingPoolMasterAddress: '0xB5eE8EF06a5Ece4C43D3Abe646276cC3AE4edF5c',
    pancakeRouterAddress: '0x9Ac64Cc6e4415144C455BD8E4837Fea55603e5c3',
    merkleDistributorCreatorAddress: '0x5c1Eb060415077fE38Dd030cbf3e8EaB6BbF947A',
    tierCalculatorAddress: '0x7999e27Ebb3b6Bb517b65A7f1dDF0D9d0Ef7D6CF',
    paymentTokens: [
      { name: "BNB", address: "0x0000000000000000000000000000000000000000", icon: 'bnb.svg'},
      { name: "BUSD", address: "0xDaaa52B192743f57f8dC4A26F9287ADCdaAA7ba3", icon: 'busd.svg'},
      { name: "USDT", address: "0x4f2CbA5e9B6C6514E2F1F4c6Be3db66Dbe4E164b", icon: 'usdt.svg'},
      { name: "USDC", address: "0xC033A42791d035cDFC7634c624adB9b8eB5906e1", icon: 'usdc.svg'}
    ],
    networkTypes: [
      { id: 1, label: 'ETH', env:'dev' },
      { id: 56, label: 'BSC', env:'dev' },
      { id: 137, label: 'Polygon', env:'dev' },
      { id: -100, label: 'Solana', env:'dev' },
      { id: -200, label: 'Tezos'},
      { id: 97, label: 'BSC Testnet', env:'dev' },
      { id: 42, label: 'Kovan', env:'dev' },
      { id: 5, label: 'ETH goerli', env: 'dev' },
      { id: 43114, label: 'Avalanche', evn: 'dev' },
      { id: 80001, label: 'Polygon Testnet', env:'dev' },
      { id: 321, label: 'KCC'},
      { id: 322, label: 'KCCTest'},
      { id: -9999100, label: 'Elrond' },
      { id: -9999101, label: 'Cardano' },
      { id: -9999102, label: 'Other' },
      { id: -9999103, label: 'Polkadot' },
      { id: -9999104, label: 'Cosmos' },
      { id: -9999105, label: 'Flow' },
      { id: -9999106, label: 'Near' },
      { id: 344435, label: 'Skale'},
      { id: 888, label: 'Wanchain'},
      { id: 1666600000, label: 'Harmony'},
      { id: 66, label: 'OKC'},
      { id: 42161, label: 'Arbitrum'},
      { id: -9999107, label: 'Sui'},
      { id: -9999108, label: 'Bitcoin'},
      { id: -9999109, label: 'Aptos'},
      { id: 10, label: 'Optimism'},
      { id: 8453, label: 'Base'},
      { id: 1284, label: 'Moonbeam'},
      { id: 592, label: 'Astar'},
      { id: 1890, label: 'Lightlink'},
      { id: 81457, label: 'Blast'},
    ]
  },
  remoteServiceBaseUrl: "https://adminapi-dev-incubat3.gpkit.link",
  authServiceBaseUrl: "https://incubat3-bullauth.gpkit.link",
 
 
  sumsupApplicantUrl: 'https://api.sumsub.com/checkus#/applicant/',
  sumsupClientId: 'gamespad.io_30564',
  defaultChainId: 97,
  supportedChains: [97, 56, 1, 5],
  collectionChains: [
    { id: 5, name: 'ETH Goerli' },
    { id: 80001, name: 'Polygon Testnet'}
  ]
};
