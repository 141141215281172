<main class="dashboard-main">
  <section class="__create-deal">
    <a href="/create-claiming-deal" mat-raised-button color="primary" class="btn-icon btn-icon-left w-100">
      <i class="icon icomoon-plus"></i>
      Create Claiming Deal
    </a>
  </section>

  <section class="__deal-list">
    <div class="card-list per-row-3">

      <div class="card" *ngFor="let item of allClaimingDeals$ | async; index as i">
        <div class="card-header d-flex align-items-center justify-content-between">

          <div class="__status __status-unlocked" *ngIf="!item.isLocked">
            <i class="icomoon-unlock"></i>
            Unlocked {{item?.dealAddress?'':' - Please link Deal'}}
          </div>
          <div class="__status __status-locked" *ngIf="item.isLocked">
            <i class="icomoon-lock"></i>
            Locked {{item?.dealAddress?'':' - Please link Deal'}}
          </div>

          <a mat-icon-button
             class="__edit-deal-btn"
             (click)="navigateToEditClaimingDeal(item?.address)"
             matTooltip="Edit Deal"
             matTooltipPosition="above">
            <span class="icomoon-edit"></span>
          </a>
        </div>

        <div class="card-content">
          <div class="token-name">
            <div class="token-name__img">
              <img [src]="item?.imageURL?item?.imageURL:'../../assets/images/no-image.svg'" class="rounded-circle">
            </div>
            <div class="token-name__info">
              <div class="token-title">{{item?.name}}</div>
              <div class="token-symbol">{{item?.tokenSymbol}}</div>
            </div>
          </div>

          <button class="address-btn --copy mb-4 w-100">
            <span [cdkCopyToClipboard]="item?.address">{{item?.address}}</span>
          </button>

          <div class="row __deal-info">
            <div class="col-6 __network">
              <div class="caption">Target Network</div>

              <div class="network-name">
                <img class="network-name__img" src="{{item?.chainId | imgNetworkPipe}}" alt="">
                <span class="network-name__title">{{item?.chainId | networkName}}</span>
              </div>
            </div>
            <div class="col-6 __progress">
              <div class="caption">Claiming Progress</div>
              <div class="progress progress-sm">
                <div class="progress-bar"
                     role="progressbar"
                     [style.width.%]="getClaimedPercent(item?.claimedAmount,item?.totalTokens)">
                </div>
              </div>
              <div class="progress-legend">
                <div class="progress-legend__percent">{{getClaimedPercent(item?.claimedAmount,item?.totalTokens)| number: '1.0-2'}}%</div>
                <div class="progress-legend__value">{{item?.claimedAmount| shiftDecimals: -item?.tokenDecimal | number: '1.0-2'}} / {{item?.totalTokens | shiftDecimals: -item?.tokenDecimal | number: '1.0-2'}}</div>
              </div>
            </div>
          </div>
        </div>


        <div class="card-footer" *ngIf="item?.dealAddress">
          <button mat-stroked-button class="btn-icon btn-icon-left w-100"
                  (click)="navigateToEditDeal(item?.dealAddress)">
            <i class="icon icomoon-unlock"></i> Open Deal
          </button>
        </div>

      </div>

    </div>
  </section>
</main>
