import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "nameTierPipe"
})
export class NameTierPipe implements PipeTransform {
  transform(index: number): string {
    return index == -1 ? "None" : (index + 1).toString();
  }
}
