<main class="dashboard-main">
  <section class="dashboard-section p-40">
    <table class="__table table w-100">
      <thead>
      <tr>
        <th>#</th>
        <th>Date</th>
        <th>%</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <!-- Default row-->
      <tr>
        <td  data-title="#">
          1
        </td>
        <td  data-title="Date">
          <span class="update-val">Less than 10 d</span>
        </td>
        <td  data-title="%">
          <span class="update-val">25%</span>
        </td>
        <td class="penalties-table__actions">
          <button mat-icon-button>
            <span class="icomoon-edit"></span>
          </button>
        </td>
      </tr>

      <!-- Edit active row-->
      <tr>
        <td  data-title="#">
          2
        </td>
        <td  data-title="Date">
          <span class="mb-3">Less than 10 d</span>
          <mat-form-field appearance="fill" hideRequiredMarker>
            <mat-label>Title</mat-label>
            <input matInput
                   type="text"
                   required>
          </mat-form-field>
        </td>
        <td  data-title="%">
          <span class="mb-3">25%</span>
          <mat-form-field appearance="fill" hideRequiredMarker>
            <mat-label>Percent</mat-label>
            <input matInput
                   type="number"
                   required>
          </mat-form-field>
        </td>
        <td class="penalties-table__actions">
          <button mat-icon-button>
            <span class="icomoon-checkmark"></span>
          </button>
        </td>
      </tr>

      <!-- Add new row-->
      <tr>
        <td  data-title="#">
          3
        </td>
        <td  data-title="Date">
          <mat-form-field appearance="fill" hideRequiredMarker>
            <mat-label>Title</mat-label>
            <input matInput
                   type="text"
                   required>
          </mat-form-field>
        </td>
        <td  data-title="%">
          <mat-form-field appearance="fill" hideRequiredMarker>
            <mat-label>Percent</mat-label>
            <input matInput
                   type="number"
                   required>
          </mat-form-field>
        </td>
        <td>
          <button mat-icon-button>
            <span class="icomoon-plus"></span>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </section>

</main>
