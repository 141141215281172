<div class="modal-header">
  <i class="icon icomoon-address-book"></i>
  <h5 class="modal-title">Change Address</h5>
  <div type="button" class="btn-close" [mat-dialog-close]="true"></div>
</div>

<div class="modal-body">

  <mat-form-field appearance="fill"
                  hideRequiredMarker
                  class="icon-form-field mb-4">
    <img src="../../assets/images/network/solana.svg" alt="">
    <mat-label>Solana Wallet</mat-label>
    <input matInput
           type="text"
           [(ngModel)]="emissionAddress"
           required
    >
    <mat-hint></mat-hint>
    <mat-error>It's not the Solana Network address or there is a mistake in the address</mat-error>
  </mat-form-field>


  <div class="btn-group-justified">
    <button mat-stroked-button
            class="btn-icon btn-icon-left"
            [mat-dialog-close]="true">
      <i class="icon icomoon-close"></i>
      Cancel
    </button>

    <button mat-raised-button
            color="primary"
            class="btn-icon btn-icon-left">
      <i class="icon icomoon-checkmark"></i>
      Confirm
    </button>
  </div>
</div>
