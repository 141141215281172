import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DlgAddressBookComponent } from '../dlg-address-book';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { ComponentBase } from '../../shared/component-base';
import { ActivatedRoute, Router } from '@angular/router';
import { UserSessionProvider } from '../../shared/user-session-provider';
import {
  AddressBookChangeRequestStatus,
  AddressBookEntryDTO,
  AddressBookRequestFullDTO,
  AddressBookRequestLiteDTO,
  AddressBookServiceProxy,
  UserDTO,
  UsersServiceProxy
} from '../../service-proxies/service-proxies';
import { environment } from '../../environments/environment';
import { filter, switchMap } from 'rxjs/operators';

@Component({
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent extends ComponentBase implements OnInit {

  constructor(
    private _dialog: MatDialog,
    private userSessionProvider: UserSessionProvider,
    private usersServiceProxy: UsersServiceProxy,
    private addressBookServiceProxy: AddressBookServiceProxy,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super();
  }
  account: string = '';
  public dbAddresses: Array<AddressBookEntryDTO> = [];
  public dbAddressChangeRequests: Array<AddressBookRequestFullDTO> = [];
  public allAddresses: Array<AddressBookEntryDTO> = [];
  public get enumStatus(): typeof AddressBookChangeRequestStatus {
    return AddressBookChangeRequestStatus;
  }
  private userid: string;
  userDto: UserDTO;

  public allAvailableNetworks = environment.bsc.networkTypes;
  async ngOnInit() {

    if (this.userSessionProvider.authToken) {
      this.eventLogin(this.userSessionProvider.linkedWallet);
    }
    else {
      console.error('Not Auth. redirect to login page');
      this.router.navigate(["/login"]);
    }
  }

  async eventLogin(username: string) {

    await this.route
      .queryParams
      .subscribe(params => {
        this.userid = params['userid'];
        console.log(`this userid : ${this.userid}`);
        //this.getDealDTO();
      });

    console.log('eventLogin - ' + username);
    if (this.account != username) {
      this.account = username;

      this.usersServiceProxy.getByUserId(this.userid)
        .subscribe(result => {
          this.userDto = result;
        },
          error => {
            console.error(error);
          });

      this.updateAddressBook();
    }
  }

  updateAddressBook() {
    this.addressBookServiceProxy.getAllByUserWallet(this.userid)
        .subscribe(result => {
          this.dbAddresses = result;
          this.filterList();
        },
          error => {
            console.error(error);
          });

      this.addressBookServiceProxy.getChangeRequestsByUserWallet(this.userid)
        .subscribe(result => {
          this.dbAddressChangeRequests = result;
        },
          error => {
            console.error(error);
          });
  }

  filterList() {
    this.allAddresses = [];

    for (const network of this.allAvailableNetworks) {
      let dto = this.dbAddresses.find(p => p.networkId == network.id);
      if (!dto) {
        dto = new AddressBookEntryDTO();
        dto.networkId = network.id;
        dto.userWallet = this.account;
      }
      this.allAddresses.push(dto);
    }
  }


  processServiceError(error: any) {
    if (error.status == 401) {
      console.error('401');
      this.userSessionProvider.finishAuth();
      this.navigateToLogin();
    }
    else {
      console.error(error);
      this.showErrorModal(JSON.parse(error.response).message);
    }
  }

  navigateToLogin(): void {
    this.router.navigate(["/login"]);
  }

  async approveAddressBookChangeRequest(dto: AddressBookRequestFullDTO): Promise<void> {
    const dbDto = new AddressBookRequestLiteDTO();
    dbDto.userWallet = dto.userWallet;
    dbDto.emissionAddress = dto.emissionAddress;
    dbDto.networkId = dto.networkId;
    console.log("approve");
    console.log({ dbDto });
     this.addressBookServiceProxy.approveChangeRequest(this.account, dbDto).subscribe(
       result => {
         this.showInfoModal("Saved");
         location.reload();
       },
       error => {
         this.processServiceError(error);
       }
     );
  }

  async declineAddressBookChangeRequest(dto: AddressBookRequestFullDTO): Promise<void> {
    let dbDto = new AddressBookRequestLiteDTO();
    dbDto.userWallet = dto.userWallet;
    dbDto.emissionAddress = dto.emissionAddress;
    dbDto.networkId = dto.networkId;
    console.log("decline");
    console.log({ dbDto });
    this.addressBookServiceProxy.declineChangeRequest(this.account, dbDto)
      .subscribe(
        result => {
          this.showInfoModal("Saved");
          location.reload();
        },
        error => {
          this.processServiceError(error);
        }
      );
  }

  async clearAddressBookRequest(): Promise<void> {
    const result = await this.showConfirmationModal(
      'Are you sure you want to delete all user addresses?',
      'Yes'
    );
    if (!result.isConfirmed) {
      return;
    }

    this.addressBookServiceProxy.clearRequest(this.userid).subscribe(
      result => {
        this.showInfoModal("Cleared");
        this.updateAddressBook();
      },
      error => {
        this.processServiceError(error);
      }
    );
  }
}
