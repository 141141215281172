import {Component, OnInit} from '@angular/core';
import {ComponentBase} from '../../shared/component-base';
import {EventBus} from '../../shared/event-bus';
import {Web3Service} from '../../shared/web3-service';
import {UserSessionProvider} from '../../shared/user-session-provider';
import {
  ClaimingDealServiceProxy,
  DealDTO, DealEventsCollectProcessStatus,
  DealServiceProxy,
  DealUpdateDTO,
  DealWhiteListUpdateDTO, ReloadDealEventsStatusDTO
} from '../../service-proxies/service-proxies';
import {ActivatedRoute, Router} from '@angular/router';
import {NetworkNamePipe} from '../../shared/pipes/networkName.pipe';
import BigNumber from 'bignumber.js';
import {environment} from '../../environments/environment';
import {QuillEditorComponent, QuillModules} from "ngx-quill";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import {CKEditor5} from "@ckeditor/ckeditor5-angular";

@Component({
  selector: 'edit-deal',
  templateUrl: './edit-deal.component.html',
  styleUrls: ['./edit-deal.component.scss'],
})
export class EditDealComponent extends ComponentBase implements OnInit {
  constructor(
    private eventBus: EventBus,
    private web3Service: Web3Service,
    private userSessionProvider: UserSessionProvider,
    private dealService: DealServiceProxy,
    private claimingDealServiceProxy: ClaimingDealServiceProxy,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super();
  }

  waiting = false;
  account = '';

  isCollectWalletDeal = false;
  dealAddress: string;
  deal: DealDTO;
  name: string;
  description: string;
  emissionDescription: string;
  projectURL: string;
  claimURL: string;
  logoURL: string;
  dealType: number;
  targetNetwork: number;
  visibleForUsers: boolean;
  hideTgeDate: boolean;
  enabledWhiteList: boolean;
  whiteListAddresses: string;
  customTiers: string;

  paymentToken: string;

  tokenPrice: string;
  weightFactor: string;
  rewardToken: string;
  collectionAddress: string;
  rewardDecimals: number;
  startTimestamp: number;
  finishTimestamp: number;
  startClaimTimestamp: number;
  maxDistributedTokenAmount: string;
  totalRaise: string; // Sum of all payments (in payment token)
  tokensForDistribution: string;
  minimumRaise: string;
  distributedTokens: string;
  allowRefund: boolean;

  vestingContractAddress: string;
  vestingPercent: number;
  vestingStart: number;
  vestingInterval: number;
  vestingDuration: number;

  // #end region contract data
  paymentDecimal: number;
  paymentTokenSymbol: string;
  rewardTokenSymbol: string;
  rewardTokenName: string;
  tokensPerETH: number;

  allTiers: any[] = [];
  allMainTiers: any[] = [];

  newTierTokenAmount: number;
  newTierTokenOnlyAmount: number;
  newTierAllocation: number = 0;
  newTierBaseWeight: number;
  public get newTierTicketSize() { 
    return this.weightFactor && this.newTierBaseWeight ? this.newTierBaseWeight * Number(this.weightFactor) : 0; 
  }

  updateTierIndex = -1;
  updateTierTokenAmount: number;
  updateTierTokenOnlyAmount: number;
  updateTierAllocation: number = 0;
  updateTierBaseWeight: number;

  public getTicketSize(i: number) { 
    return this.allTiers[i].baseWeight * Number(this.weightFactor); 
  }

  public get updateTierTicketSize() { 
    return this.weightFactor && this.updateTierBaseWeight ? this.updateTierBaseWeight * Number(this.weightFactor) : 0; 
  }


  socialTwitter: string;
  socialMedium: string;
  socialYouTube: string;
  socialFacebook: string;
  socialTelegram: string;
  socialLinkedIn: string;
  socialInstagram: string;
  socialTelegramChannel: string;
  disclaimerText: string;
  disclaimer: string;
  showDisclaimer: boolean;

  updateTimeTimerId: NodeJS.Timeout;
  now: number;
  dealTypes = [
    { id: 1, label: 'IDO' },
    { id: 0, label: 'VC' },
    { id: 2, label: 'INO' }
  ];

  quillStyles = {
    'min-height': '100px',
    'background-color': '#353E51'
  };
  quillConfig: QuillModules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'link'],
      [{ list: 'ordered'}, { list: 'bullet' }],
      ['clean'],
      ['image'],
    ],
  };

  public Editor: any = Editor;

  public config: CKEditor5.Config = {
    htmlSupport: {
      allow: [
        {
          name: /.*/,
          attributes: true,
          classes: true,
          styles: true
        }
      ]
    },
    link: {
      defaultProtocol: 'http://'
    },
    mediaEmbed: {
      previewsInData: true
    }
  }

  editor: any;

  networkTypes: any = environment.bsc.networkTypes;
  collectionChains: any = environment.collectionChains;
  collectionChain: any = environment.collectionChains[0];
  dealEvents: string;
  eventExtractionsStatus: string;
  eventExtractionsStatusDto: ReloadDealEventsStatusDTO;

  async ngOnInit() {
    this.now = Math.floor(Date.now() / 1000);

    this.updateTimeTimerId = setInterval(() => {
      this.now = Math.floor(Date.now() / 1000);

    }, 1000);

    await this.web3Service.initWeb3();

    this.route
      .queryParams
      .subscribe(params => {
        this.dealAddress = params.address;
        console.log(`deal address: ${this.dealAddress}`);
        this.getDealDTO();
      });

    this.eventBus.loginEvent.subscribe(result => {
      console.log('loginEvent subscription:' + result);
      this.eventLogin(result);
    });

    this.eventBus.logoutEvent.subscribe(result => {
      console.log('logoutEvent subscription:' + result);
      this.eventLogout();
    });

    // this.eventBus.outputNetworkChanged.subscribe(result => {
    //  console.log('outputNetworkChanged subscription:' + result);
    //  this.setToNetworkAndPairTo(result);
    // });

    // this.eventBus.fromPairChanged.subscribe(result => {
    //  console.log('fromPairChanged subscription:' + result);
    //  this.setPairFrom(result);
    // });
    this.account = this.userSessionProvider.linkedWallet;
    this.updateDealContractData();
  }

  getEditorInstance(handler: QuillEditorComponent): void {
    this.editor = handler;
    const toolbar = this.editor.getModule('toolbar');
    const image = toolbar?.addHandler('image', () => {
      this.handleImage();
    });
    image.handler = this.handleImage.bind(this);
  }

  handleImage(): void {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.onchange = () => {
      const file = input.files[0];
      const maxSize = 2 * 1024 * 1024; // 2 MB
      if (file && file.size > maxSize) {
        alert(`Image size exceeds the maximum allowed size of 2 MB`);
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          const range = this.editor.getSelection(true);
          const index = range.index + range.length;
          this.editor.insertEmbed(index, 'image', reader.result);
        };
        reader.readAsDataURL(file);
      }
    };
    input.click();
  }

  addHttpsToLinks(editor: QuillEditorComponent): void {
    const links = editor.quillEditor.root.getElementsByTagName('a');
    for (let i = 0; i < links.length; i++) {
      if (!links[i].attributes.href.value.startsWith('https:')) {
        if (links[i].attributes.href.value.startsWith('http://')) {
          links[i].href = links[i].attributes.href.value;
        }else {
          links[i].href = 'https://' + links[i].attributes.href.value;
        }
      }
    }
  }

  getDealDTO() {
    this.dealService.getByAddress(this.dealAddress).subscribe(result => {
      this.deal = result;
      this.rewardTokenSymbol = this.deal.rewardTokenSymbol;
      this.rewardToken = this.deal.rewardToken;
      this.collectionAddress = this.deal.collectionAddress;
      this.isCollectWalletDeal = this.deal.isDealWithoutDistribution;
      this.name = this.deal.name;
      this.description = this.deal.description;
      this.emissionDescription = this.deal.emissionDescription;
      this.projectURL = this.deal.url;
      this.logoURL = this.deal.logoURL;
      this.dealType = this.deal.dealType;
      this.visibleForUsers = this.deal.visibleForUsers;
      this.hideTgeDate = this.deal.hideTgeDate;
      this.disclaimerText = this.deal.disclaimerText;
      this.disclaimer = this.deal.disclaimer;
      this.showDisclaimer = this.deal.showDisclaimer;

      this.enabledWhiteList = this.deal.enabledWhitelisting;
      this.whiteListAddresses = this.deal.whitelistingAddresses;

      this.socialTwitter = this.deal.twitterURL;
      this.socialMedium = this.deal.mediumURL;
      this.socialYouTube = this.deal.youTubeURL;
      this.socialFacebook = this.deal.facebookURL;
      this.socialTelegram = this.deal.telegramURL;
      this.socialLinkedIn = this.deal.linkedInURL;
      this.socialInstagram = this.deal.instagramURL;
      this.socialTelegramChannel = this.deal.telegramChannelURL;
      this.targetNetwork = this.deal.targetNetwork;
      this.claimURL = this.deal.claimURL;

      if (result.collectionChainId)
      {
        this.selectChain(result.collectionChainId);
      }

      console.log(this.deal);
      if (this.web3Service.chainIdNumber != this.deal.chainId) {
        const chainName = new NetworkNamePipe().transform(this.deal.chainId);
        this.showErrorModal(`Select ${chainName} network in metamask!`);
        this.router.navigate(['/deals']);
      }
    },
      error => {
        this.processServiceError(error);
      });
    this.showEventsExtractionStatus();
  }

  eventLogin(username: string): void {
    console.log('eventLogin');
    console.log(username);
    this.account = username;
  }

  eventLogout(): void {
    this.account = '';
    // this.balance = null;
    // console.log('eventLogout')
    // if (this.timerId) {
    //  console.log('clearInterval');
    //  clearInterval(this.timerId);
    // }
  }


  async updateDealContractData() {

    console.log(`Get contract data ${this.dealAddress}`);
    this.paymentToken = await this.web3Service.getDealPaymentToken(this.dealAddress);
    if (this.paymentToken != '0x0000000000000000000000000000000000000000') {
      this.web3Service.GetContractSymbol(this.paymentToken).then((resp) => {
        this.paymentTokenSymbol = resp;
      });
      this.paymentDecimal = parseInt(await this.web3Service.GetDecimals(this.paymentToken));
    }
    else {
      // this.paymentTokenSymbol = get from DTO
      this.paymentDecimal = 18;
    }
    this.tokenPrice = await this.web3Service.getDealTokenPrice(this.dealAddress);

    let weightFactor = await this.web3Service.getDealWeightFactor(this.dealAddress);
    this.weightFactor = (Number(weightFactor) / 100).toFixed(2);

    console.log(`isCollectWalletDeal: ${this.isCollectWalletDeal}`);
    // TODO: if another chain isCollectWalletDeal = true
    if (!this.isCollectWalletDeal) {
      this.rewardToken = await this.web3Service.getDealRewardToken(this.dealAddress);
      this.web3Service.GetContractSymbol(this.rewardToken).then((resp) => {
        this.rewardTokenSymbol = resp;
      });
      this.web3Service.GetContractName(this.rewardToken).then((resp) => {
        this.rewardTokenName = resp;
      });
    }

    if (this.isCollectWalletDeal) {
      this.rewardDecimals = 18;
    }
    else {
      this.rewardDecimals = parseInt(await this.web3Service.getDealDecimals(this.dealAddress));
    }
    this.tokensPerETH = new BigNumber(1).shiftedBy(this.paymentDecimal).dividedBy(this.tokenPrice).toNumber();

    this.startTimestamp = parseInt(await this.web3Service.getDealStartTimestamp(this.dealAddress));
    this.finishTimestamp = parseInt(await this.web3Service.getDealFinishTimestamp(this.dealAddress));
    this.startClaimTimestamp = parseInt(await this.web3Service.getDealStartClaimTimestamp(this.dealAddress));

    this.web3Service.getDealMaxDistributedTokenAmount(this.dealAddress).then((resp) => {
      this.maxDistributedTokenAmount = resp;
      this.maxDistributedTokenAmount = new BigNumber(this.maxDistributedTokenAmount).shiftedBy(-this.rewardDecimals).toFixed();
    });

    this.web3Service.getDealMinimumRaise(this.dealAddress).then((resp) => {
      this.minimumRaise = resp;
    });

    this.web3Service.getDealAllowRefund(this.dealAddress).then((resp) => {
      this.allowRefund = Boolean(resp);
    });


    this.allTiers = [];
    const tiersLength = parseInt(await this.web3Service.getDealTiersLength(this.dealAddress));
    for (let i = 0; i < tiersLength; i++) {
      const tier = await this.web3Service.getDealTiers(this.dealAddress, i);
      console.log(tier);
      this.allTiers.push(tier);
    }
    this.updateMainTiersContractData();

    this.web3Service.getVestingPercent(this.dealAddress).then((resp) => {
      this.vestingPercent = parseInt(resp);
    });

    const vestAddress = await this.web3Service.getDealVestingAddress(this.dealAddress);
    this.vestingContractAddress = vestAddress == '0x0000000000000000000000000000000000000000' ? null : vestAddress;

    if (this.vestingContractAddress) {

      this.web3Service.getVVestingStart(this.vestingContractAddress).then((resp) => {
        this.vestingStart = parseInt(resp);
      });

      this.web3Service.getVVestingInterval(this.vestingContractAddress).then((resp) => {
        this.vestingInterval = parseInt(resp);
      });

      this.web3Service.getVVestingDuration(this.vestingContractAddress).then((resp) => {
        this.vestingDuration = parseInt(resp);
      });
    }
  }

  async updateMainTiersContractData() {
    console.log('updateMainTiersContractData');

    this.allMainTiers = [];
    const tiersLength = parseInt(await this.web3Service.getDealLockupsTiersLength());
    for (let i = 0; i < tiersLength; i++) {
      const tier = await this.web3Service.getDealLockupsTiers(i);
      console.log(tier);
      this.allMainTiers.push(tier);
    }
    if (this.allMainTiers.length > this.allTiers.length) {
      this.newTierTokenAmount = this.toNumberFromWei(this.allMainTiers[this.allTiers.length].tokenAmount, 18);
      this.newTierTokenOnlyAmount = this.toNumberFromWei(this.allMainTiers[this.allTiers.length].tokenOnlyAmount, 18);
      this.newTierBaseWeight = this.allMainTiers[this.allTiers.length].baseWeight;
    }

  }

  async saveNewTimeClick(): Promise<void> {
    this.waiting = true;

    // const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

    const contractEventsSource = this.web3Service.dealUpdateTime(this.dealAddress, this.account, this.startTimestamp, this.finishTimestamp, this.startClaimTimestamp);

    // contractEventsSource.transactionHash$
    //  .pipe(tap(() => this._dlgContractSrv.showSubmitted()))
    //  .subscribe();

    try {
      const response = await contractEventsSource.receipt$.toPromise();
      console.log(response);
      // dialogRef.close();
      // this._alertSrv.show('Confirmed transaction');
      this.showInfoModal('Confirmed transaction');
      this.updateDealContractData();

    } catch (err) {
      // dialogRef.close();
      console.info('catch');
      console.info(err);
    }
    this.waiting = false;
  }

  async setWeightFactorClick(): Promise<void> {
    this.waiting = true;

    const contractEventsSource = this.web3Service.dealSetWeightFactor(this.dealAddress, this.account, this.weightFactor);

    try {
      const response = await contractEventsSource.receipt$.toPromise();
      console.log(response);
      this.showInfoModal('Confirmed transaction');
      this.updateDealContractData();
    } catch (err) {
      this.showErrorModal(JSON.stringify(err));
      console.info('catch');
      console.info(err);
    }
    finally {
      this.waiting = false;
    }
  }

  async syncTimeDb(): Promise<void> {
    this.waiting = true;

    this.waiting = true;

    this.dealService.syncTimestamp(this.dealAddress)
      .subscribe(
        result => {
          this.waiting = false;
          this.showInfoModal('Saved');
          this.getDealDTO();
        },
        error => {
          this.waiting = false;
          this.processServiceError(error);
        }
      );
  }

  async updateDealDb(): Promise<void> {
    this.waiting = true;

    const data: DealUpdateDTO = new DealUpdateDTO();
    data.dealAddress = this.dealAddress;
    data.name = this.name;
    data.description = this.description;
    data.emissionDescription = this.emissionDescription;
    data.url = this.projectURL;
    data.logoURL = this.logoURL;
    data.dealType = this.dealType;
    data.visibleForUsers = this.visibleForUsers;
    data.hideTgeDate = this.hideTgeDate;
    data.twitterURL = this.socialTwitter;
    data.mediumURL = this.socialMedium;
    data.youTubeURL = this.socialYouTube;
    data.facebookURL = this.socialFacebook;
    data.telegramURL = this.socialTelegram;
    data.linkedInURL = this.socialLinkedIn;
    data.instagramURL = this.socialInstagram;
    data.telegramChannelURL = this.socialTelegramChannel;
    data.targetNetwork = this.targetNetwork;
    console.log("claim url", this.claimURL);
    data.claimURL = this.claimURL;
    data.showDisclaimer = this.showDisclaimer;
    data.disclaimerText = this.disclaimerText;
    data.disclaimer = this.disclaimer;

    console.log({data});

    this.dealService.update(data)
      .subscribe(
        result => {
          this.waiting = false;
          this.showInfoModal('Saved');
        },
        error => {
          this.waiting = false;
          this.processServiceError(error);
        }
      );
  }

  async UpdateRewardTokenSymbol(): Promise<void> {
    this.waiting = true;

    this.dealService.updateRewardTokenSymbol(this.dealAddress, this.rewardTokenSymbol)
      .subscribe(
        result => {
          this.waiting = false;
          this.showInfoModal('Saved');
        },
        error => {
          this.waiting = false;
          this.processServiceError(error);
        }
      );
  }

  async updateWhitelist(): Promise<void> {
    this.waiting = true;

    const data: DealWhiteListUpdateDTO = new DealWhiteListUpdateDTO();
    data.dealAddress = this.dealAddress;
    data.enabledWhiteList = this.enabledWhiteList;
    data.addresses = this.whiteListAddresses;

    this.waiting = true;

    this.dealService.updateWhiteList(data)
      .subscribe(
        result => {
          this.waiting = false;
          this.showInfoModal('Saved');
        },
        error => {
          this.waiting = false;
          this.processServiceError(error);
        }
      );
  }

  async addCustomTiers(): Promise<void> {
    let users = [];
    let tiers = [];
    let eventSource: any;

    try {
      let strings = this.customTiers.split("\n");

      for (let i = 0; i < strings.length; i++) {
        let row = strings[i].split(",");
        users.push(row[0]);
        tiers.push(row[1]);
      }

      eventSource = this.web3Service.dealAddCustomTiers(this.dealAddress, this.account, users, tiers);
    }
    catch {
      this.showErrorModal('Invalid data');
      return;
    }

    this.waiting = true;

    try {
      await eventSource.receipt$.toPromise();
      this.showInfoModal('Confirmed transaction');
    } catch (err: any) {
      this.showErrorModal(err.reason || err.message || 'Error');
      console.info(err);
    }
    finally {
      this.waiting = false;
    }
  }

  async removeCustomTiers(): Promise<void> {
    let eventSource: any;

    try {
      let users = this.customTiers.split("\n");
      eventSource = this.web3Service.dealRemoveCustomTiers(this.dealAddress, this.account, users);
    }
    catch {
      this.showErrorModal('Invalid data');
      return;
    }

    this.waiting = true;

    try {
      await eventSource.receipt$.toPromise();
      this.showInfoModal('Confirmed transaction');
    } catch (err: any) {
      this.showErrorModal(err.reason || err.message || 'Error');
      console.info(err);
    }
    finally {
      this.waiting = false;
    }
  }

  async createVestingClick(): Promise<void> {

    this.waiting = true;

    // const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

    const contractEventsSource = this.web3Service.dealCreateVestingContract(
      this.account, this.dealAddress, this.vestingPercent, this.vestingStart, this.vestingInterval, this.vestingDuration);

    // contractEventsSource.transactionHash$
    //  .pipe(tap(() => this._dlgContractSrv.showSubmitted()))
    //  .subscribe();

    try {
      await contractEventsSource.receipt$.toPromise();
      // dialogRef.close();
      this.showInfoModal('Confirmed transaction');
      this.updateDealContractData();
    } catch (err) {
      // dialogRef.close();
      console.info('catch');
      console.info(err);
    }

    this.waiting = false;
  }

  async saveVestingPercentClick(): Promise<void> {

    this.waiting = true;

    // const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

    const contractEventsSource = this.web3Service.dealUpdateVestingPercent(this.account, this.dealAddress, this.vestingPercent);

    // contractEventsSource.transactionHash$
    //  .pipe(tap(() => this._dlgContractSrv.showSubmitted()))
    //  .subscribe();

    try {
      await contractEventsSource.receipt$.toPromise();
      // dialogRef.close();
      this.showInfoModal('Confirmed transaction');
      this.updateDealContractData();
    } catch (err) {
      // dialogRef.close();
      console.info('catch');
      console.info(err);
    }

    this.waiting = false;
  }

  async updateVestingClick(): Promise<void> {

    this.waiting = true;

    // const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

    const contractEventsSource = this.web3Service.vestingUpdateVestingTime(
      this.account, this.vestingContractAddress, this.vestingStart, this.vestingInterval, this.vestingDuration);

    // contractEventsSource.transactionHash$
    //  .pipe(tap(() => this._dlgContractSrv.showSubmitted()))
    //  .subscribe();

    try {
      await contractEventsSource.receipt$.toPromise();
      // dialogRef.close();
      this.showInfoModal('Confirmed transaction');
      this.updateDealContractData();
    } catch (err) {
      // dialogRef.close();
      console.info('catch');
      console.info(err);
    }

    this.waiting = false;
  }

  select(index: number) {
    this.updateTierIndex = index;
    this.updateTierTokenAmount = this.toNumberFromWei(this.allTiers[index].tokenAmount, 18);
    this.updateTierTokenOnlyAmount = this.toNumberFromWei(this.allTiers[index].tokenOnlyAmount, 18);
    this.updateTierBaseWeight = this.allTiers[index].baseWeight;
  }

  async updateTierClick(): Promise<void> {
    this.waiting = true;

    try {
      const contractEventsSource = this.web3Service.dealUpdateTier(
        this.account, 
        this.dealAddress, 
        this.updateTierIndex, 
        this.updateTierTokenOnlyAmount,   // set tokenAmount the same as tokenOnlyAmount
        this.updateTierTokenOnlyAmount, 
        this.updateTierBaseWeight, 
        this.updateTierAllocation, 
        this.deal.paymentDecimal, 
        this.deal.rewardDecimal
      );

      await contractEventsSource.receipt$.toPromise();

      this.showInfoModal('Confirmed transaction');
      this.updateTierIndex = -1;
      this.updateTierTokenAmount = null;
      this.updateTierTokenOnlyAmount = null;
      this.updateTierBaseWeight = null;
      this.updateDealContractData();
    } catch (err) {
      this.showErrorModal(JSON.stringify(err));
      console.info('catch');
      console.info(err);
    }

    this.waiting = false;
  }

  async addTierClick(): Promise<void> {
    this.waiting = true;

    try {
      const contractEventsSource = this.web3Service.dealAddTier(
        this.account, 
        this.dealAddress, 
        this.newTierTokenOnlyAmount,      // set tokenAmount the same as tokenOnlyAmount
        this.newTierTokenOnlyAmount, 
        this.newTierBaseWeight, 
        this.newTierAllocation,
        this.deal.paymentDecimal, 
        this.deal.rewardDecimal
      );

      await contractEventsSource.receipt$.toPromise();

      this.showInfoModal('Confirmed transaction');
      this.updateDealContractData();
      this.newTierTokenAmount = null;
      this.newTierTokenOnlyAmount = null;
      this.newTierBaseWeight = null;
    } catch (err) {
      this.showErrorModal(JSON.stringify(err));
      console.info('catch');
      console.info(err);
    }

    this.waiting = false;
  }

  async withdrawFundsClick(): Promise<void> {
    this.waiting = true;

    const contractEventsSource = this.web3Service.dealWithdrawFunds(this.account, this.dealAddress);

    try {
      await contractEventsSource.receipt$.toPromise();

      this.showInfoModal('Confirmed transaction');
      this.updateDealContractData();
      this.newTierTokenAmount = null;
      this.newTierTokenOnlyAmount = null;
    } catch (err) {
      this.showErrorModal(JSON.stringify(err));
      console.info('catch');
      console.info(err);
    }

    this.waiting = false;
  }

  async withdrawNotSoldTokensClick(): Promise<void> {
    this.waiting = true;

    const contractEventsSource = this.web3Service.dealWithdrawNotSoldTokens(this.account, this.dealAddress);

    try {
      await contractEventsSource.receipt$.toPromise();

      this.showInfoModal('Confirmed transaction');
      this.updateDealContractData();
      this.newTierTokenAmount = null;
      this.newTierTokenOnlyAmount = null;
    } catch (err) {
      this.showErrorModal(JSON.stringify(err));
      console.info('catch');
      console.info(err);
    }

    this.waiting = false;
  }

  processServiceError(error: any) {
    if (error.status == 401) {
      console.error('401');
      this.userSessionProvider.finishAuth();
      this.navigateToLogin();
    }
    else {
      console.error(error);
      this.showErrorModal(JSON.parse(error.response).message);
    }
  }

  navigateToLogin(): void {
    this.router.navigate(['/login']);
  }

  async extractDealPaymentEventsClick(): Promise<void>  {

    this.dealService.collectDealEvents(this.dealAddress)
      .subscribe(
        result => {
          this.showInfoModal('Events Collected');
        },
        error => {
          this.processServiceError(error);
        }
      );
  }

  async updateRewardAddress(): Promise<void> {
    this.waiting = true;

    this.dealService.updateRewardTokenAddress(this.dealAddress, this.rewardToken)
      .subscribe(
        result => {
          this.waiting = false;
          this.showInfoModal('Saved');
        },
        error => {
          this.waiting = false;
          this.processServiceError(error);
        }
      );
  }

  async updateCollection(): Promise<void> {
    this.waiting = true;

    this.dealService.updateCollection(this.dealAddress, this.collectionAddress, this.collectionChain.id)
      .subscribe(
        result => {
          this.waiting = false;
          this.showInfoModal('Saved');
        },
        error => {
          this.waiting = false;
          this.processServiceError(error);
        }
      );
  }

  selectChain(chainId: number) {
    this.collectionChain = this.collectionChains.find((x: { id: number; }) => x.id == chainId);
  }

  async saveMaxDistributedTokenAmountClick(): Promise<void> {
    this.waiting = true;

    const stringMaxDistributedTokenAmount = new BigNumber(this.maxDistributedTokenAmount).shiftedBy(this.rewardDecimals).toFixed();
    const contractEventsSource = this.web3Service.dealUpdateMaxDistributedTokenAmount(this.dealAddress, this.account, stringMaxDistributedTokenAmount);

    try {
      const response = await contractEventsSource.receipt$.toPromise();
      console.log(response);
      this.showInfoModal('Confirmed transaction');
      this.updateDealContractData();

    } catch (err) {
      console.info('catch');
      console.info(err);
    }
    this.waiting = false;
  }

  async syncMaxDistributedTokenAmountDb(): Promise<void> {
    this.waiting = true;

    const stringMaxDistributedTokenAmount = new BigNumber(this.maxDistributedTokenAmount).shiftedBy(this.rewardDecimals).toFixed();

    this.dealService.updateMaxDistributedTokenAmount(this.dealAddress, stringMaxDistributedTokenAmount)
      .subscribe(
        result => {
          this.waiting = false;
          this.showInfoModal('Saved');
        },
        error => {
          this.waiting = false;
          this.processServiceError(error);
        }
      );
  }

  showDealEventsClick() {
    this.dealService.dealEventsToCsv(this.dealAddress).subscribe(result => {
      this.dealEvents = result;
    },
      error => {
        this.processServiceError(error);
      });
  }

  showEventsExtractionStatus() {
    this.dealService.getReloadDealEventsStatus(this.dealAddress).subscribe(result => {
      this.eventExtractionsStatusDto = result;
      this.eventExtractionsStatus = 'Status =' + DealEventsCollectProcessStatus[result.status].toString() +
        '\n% of completion = ' + result.completionPercent +
        '\n Start time = ' + result.startTimestamp +
        '\n End time = ' + result.endTimestamp;
     },
      error => {
        this.processServiceError(error);
      });
  }

  isEventCollectionInProgress() {
    return this.eventExtractionsStatusDto?.status == DealEventsCollectProcessStatus.InProgress;
  }
}
