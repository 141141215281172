import { NgModule } from "@angular/core";

import * as ApiServiceProxies from "./service-proxies";

@NgModule({
    providers: [
    ApiServiceProxies.AccountServiceProxy,
    //ApiServiceProxies.PaybackEventsServiceProxy,
    //ApiServiceProxies.TransitEventServiceProxy,
    ApiServiceProxies.AdminServiceProxy,
    ApiServiceProxies.DealServiceProxy,
    ApiServiceProxies.StakingPoolServiceProxy,
    ApiServiceProxies.UsersServiceProxy,
    ApiServiceProxies.AddressBookServiceProxy,
    ApiServiceProxies.ClaimingDealServiceProxy,
    ApiServiceProxies.UserMessagesServiceProxy,
    ApiServiceProxies.NotificationServiceProxy,
    ]
})
export class ServiceProxyModule { }
