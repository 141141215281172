import {Component, Injector, OnInit} from '@angular/core';
import {PollsServiceProxy} from '../../service-proxies/service-proxies';
import {ComponentBase} from '../../shared/component-base';
import {Router} from '@angular/router';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {UserSessionProvider} from '../../shared/user-session-provider';
import {DatePipe} from '@angular/common';


@Component({
  selector: 'app-user-polls',
  templateUrl: './user-polls.component.html',
  styleUrls: ['./user-polls.component.scss']
})

export class UserPollsComponent extends ComponentBase implements OnInit {
  constructor(private pollsService: PollsServiceProxy, private router: Router, private userSessionProvider: UserSessionProvider, private datePipe: DatePipe) {
    super();
  }
  public polls: any = [];

  async ngOnInit(): Promise<void> {
    this.getPolls();
  }

  async getPolls(): Promise<void> {
    this.polls = await this.pollsService.getPolls().toPromise();
    this.polls.forEach((poll: any) => {
      const totalVotes = poll.votes.length;
      poll.options.forEach((option: any) => {
        const votesForOption = poll.votes.filter((vote: any) => vote.pollOptionId === option.id).length;
        option.percentage = totalVotes > 0 ? parseFloat(String((votesForOption / totalVotes) * 100)).toFixed(0) : 0;
      });
    });
    this.polls.sort((a: any, b: any) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());
  }
  editUserPoll(id: string): void {
    this.router.navigate(['/edit-poll'], { queryParams: { pollId: id }});
  }

  deletePoll(id: string): void {
    this.pollsService.deletePoll(id).subscribe(
      res => {
        this.getPolls();
      },
      error => {
        console.log(error);
      }
    );
  }

  visibilityChange(event: MatSlideToggleChange | any, poll: any): any {
    delete poll.votes;
    const formValues = {
      ...poll,
      isVisible: event.checked,
    };
    this.pollsService.updatePoll(formValues).subscribe(
      res => {
        this.getPolls();
      },
      error => {
        console.log(error);
      }
    );
  }

  finishPoll(poll: any): any {
    delete poll.votes;
    const formValues = {
      ...poll,
      isFinished: !poll.isFinished,
    };
    this.pollsService.updatePoll(formValues).subscribe(
      res => {
        this.getPolls();
      },
      error => {
        console.log(error);
      }
    );
  }

  addExtraVote(pollId: string, optionId: string) {
    this.pollsService.addExtraVote(pollId, optionId).subscribe(res => {
        this.getPolls();
      },
      error => {
        console.log(error)
      })
  }

  optionUserVotedFor(optionId: number): boolean {
    return this.polls.some((poll: any) => poll.votes.some((vote: any) => vote.pollOptionId === optionId && vote.userWallet === this.userSessionProvider.linkedWallet));
  }

  showLocalDate(date: any): string {
    const utc = date + 'Z';
    return this.datePipe.transform(new Date(utc), 'dd MMM, H:mm');
  }
}
