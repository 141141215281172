import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ComponentBase } from '../../shared/component-base';
import { EventBus } from '../../shared/event-bus';
import { Web3Service } from '../../shared/web3-service';
import Web3 from 'web3';
import { Router } from '@angular/router';
import { UserSessionProvider } from '../../shared/user-session-provider';
import { environment } from '../../environments/environment';

@Component({
  templateUrl: './create-wallet-deal.component.html',
  // styleUrls: ['./deals.component.scss'],
})
export class CreateWalletDealComponent extends ComponentBase implements OnInit, OnDestroy {
  constructor(
    private eventBus: EventBus,
    private web3Service: Web3Service,
    private _router: Router,
    private userSessionProvider: UserSessionProvider
  ) {
    super();
  }

  web3ChainId: number;
  waiting: boolean = false;

  step: number = 1;
  //creatingPoolProcess: boolean = false;
  //creatingPoolTx: string;
  //poolCreated: boolean= false;

  tokenPrice: number;

  paymentToken: { name: string, address: string, icon: string } = environment.bsc.paymentTokens[0];
  allPaymentTokens: any = environment.bsc.paymentTokens;

  startTimestamp: number;
  finishTimestamp: number;
  startClaimTimestamp: number;

  minimumRaise: number = 0;
  maxDistributedTokenAmount: number;
  weightFactor: number = 1;

  account: string;
  balance: number = 0;
  allowRefund: boolean = false;

  cretedDealAddress: string;

  timerId: NodeJS.Timeout;


  async ngOnInit() {
    this.eventBus.loginEvent.subscribe(result => {
      console.log('loginEvent subscription:' + result);
      this.eventLogin(result);
    });

    this.eventBus.logoutEvent.subscribe(result => {
      console.log('logoutEvent subscription:' + result);
      this.eventLogout();
    });

    await this.web3Service.initWeb3();
    if (this.userSessionProvider.linkedWallet) {
      this.eventLogin(this.userSessionProvider.linkedWallet);
    }
    this.web3ChainId = this.web3Service.chainIdNumber;
  }

  async ngOnDestroy() {
    if (this.timerId) {
      clearInterval(this.timerId);
    }
  }

  eventLogin(username: string): void {
    console.log('eventLogin');
    console.log(username);
    if (this.account != username) {
      this.account = username;
      this.updateBalanceData();

      this.timerId = setInterval(() => {
        this.updateBalanceData()
      }, this.expectedBlockTime);
    }
  }

  eventLogout(): void {
    this.account = "";
    this.balance = null;
    console.log('eventLogout')
    if (this.timerId) {
      console.log('clearInterval');
      clearInterval(this.timerId);
    }
  }

  async updateBalanceData() {
    console.log('updateBalanceData');

    //this.web3Service.GetTokenBalance(this.account, this.feeTokenAddress).then((balance) => {
    //  this.balance = this.toNumberFromWei(balance, 18);
    //});
    //this.web3Service.GetAllowance(this.account, this.feeTokenAddress, this.idoCreatorAddress).then((resp) => {
    //  this.allowanceBCS = this.toNumberFromWei(resp, 18);
    //});
  }


  toStep1(): void {
    this.step = 1;
  }

  async toStep2() {
    this.step = 2;
  }


  async createDealClick(): Promise<void> {
    let paymentDecimal = 18;
    if (this.paymentToken.address != "0x0000000000000000000000000000000000000000") {
      paymentDecimal = parseInt(await this.web3Service.GetDecimals(this.paymentToken.address));
    }

    if (paymentDecimal) {
      this.waiting = true;

      //const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

      const contractEventsSource = this.web3Service.createCollectWalletDeal(this.account, this.paymentToken.address, paymentDecimal, this.tokenPrice,
        this.startTimestamp, this.finishTimestamp, this.startClaimTimestamp,
        this.minimumRaise, this.maxDistributedTokenAmount, this.weightFactor,
        this.allowRefund);

      //contractEventsSource.transactionHash$
      //  .pipe(tap(() => this._dlgContractSrv.showSubmitted()))
      //  .subscribe();

      try {
        let response = await contractEventsSource.receipt$.toPromise();
        console.log(response);
        //dialogRef.close();
        //this._alertSrv.show('Confirmed transaction');
        this.showInfoModal('Confirmed transaction');

        console.log(response.events.DealCreated);
        this._router.navigate(['/edit-deal'], { queryParams: { address: response.events.DealCreated.returnValues.dealAddress } });

      } catch (err) {
        //dialogRef.close();
        console.info('catch');
        console.info(err);
      }

      this.waiting = false;
    }
  }
}
