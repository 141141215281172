import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';

import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ClipboardModule } from '@angular/cdk/clipboard';

import { AppRoutingModule } from './app-routing.module';
import { AppMobileHeaderComponent } from './app-mobile-header/app-mobile-header.component';
import { SharedDlgModule } from './shared-dlg.module';
import { AppComponent } from './app.component';
import { AppDashboardHeaderComponent } from './app-dashboard-header/app-dashboard-header.component';
import { DealDetailComponent } from './deal-detail/deal-detail.component';
import { EditDealComponent } from './edit-deal/edit-deal.component';
import { UsersComponent } from './users/users.component';
import { CreateDealComponent } from './create-deal/create-deal.component';
import { DealsComponent } from './deals/deals.component';
import { MainPageComponent } from './main/main.component';
import { AppAsideComponent } from './app-aside/app-aside.component';
import { AppFooterComponent } from './app-footer/app-footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppWalletComponent } from './app-wallet/app-wallet.component';
import { DlgSwitchNetworkComponent } from './dlg-switch-network';
import { DlgWalletComponent } from './dlg-wallet';
import { DlgUnlockWalletComponent } from './dlg-unlock-wallet';
import { DlgContractProcessingComponent } from './dlg-contract-processing';
import { DlgContractSubmittedComponent } from './dlg-contract-submitted';

import { DlgContractService } from './dlg-contract.service';

import { BrowserStorageProvider } from '../shared/browser-storage-provider';
import { UserSessionProvider } from '../shared/user-session-provider';
import { EventBus } from '../shared/event-bus';
import { Web3Service } from '../shared/web3-service';
import { PipesModule } from '../shared/pipes/pipes.module';
import { ServiceProxyModule } from '../service-proxies/service-proxy.module';
import {
  API_BASE_URL,
  AUTH_BASE_URL,
  PollsServiceProxy,
  StakingPoolServiceProxy
} from '../service-proxies/service-proxies';
import { environment } from '../environments/environment';
import { LoginPageComponent } from './login/login.component';
import { DealViewerComponent } from './deal-viewer/deal-viewer.component';
import { AdminsComponent } from './admins/admins.component';
import { TiersPageComponent } from './tiers/tiers.component';
import { CreateWalletDealComponent } from './create-wallet-deal/create-wallet-deal.component';
import { StakingComponent } from './staking/staking.component';
import { FarmingComponent } from './farming/farming.component';
import { PoolDetailComponent } from './pool-detail/pool-detail.component';
import { CreatePoolComponent } from './create-pool/create-pool.component';
import { EditPoolComponent } from './edit-pool/edit-pool.component';
import { PoolViewerComponent } from './pool-viewer/pool-viewer.component';
import { DlgLockerPoolComponent } from './dlg-locker-pool';
import { PenaltiesComponent } from './penalties/penalties.component';
import { SettingsComponent } from './settings/settings.component';
import { ClaimingComponent } from './claiming/claiming.component';
import { ClaimingDealDetailComponent } from './claiming-deal-detail/claiming-deal-detail.component';
import { EditClaimingDealComponent } from './edit-claiming-deal/edit-claiming-deal.component';
import { CreateClaimingDealComponent } from './create-claiming-deal/create-claiming-deal.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { DlgAddressBookComponent } from './dlg-address-book';
import { UsersWalletComponent } from './users-wallet/users-wallet.component';
import {MatPaginatorModule} from "@angular/material/paginator";
import {AuthInterceptor} from "../interceptors/auth-interceptor";
import { UserPollsComponent } from './user-polls/user-polls.component';
import { CreateUserPollComponent } from './create-user-poll/create-user-poll.component';
import {EditUserPollComponent} from './edit-user-poll/edit-user-poll.component';
import {DatePipe} from '@angular/common';
import {QuillModule} from 'ngx-quill';
import { DlgNotificationComponent } from './dlg-notification';
import {CKEditorModule} from "@ckeditor/ckeditor5-angular";

@NgModule({
    imports: [
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatTableModule,
        MatDialogModule,
        MatIconModule,
        MatTabsModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        MatTooltipModule,
        MatSlideToggleModule,
        MatSortModule,
        MatMenuModule,
        FormsModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        PipesModule,
        HttpClientModule,
        ServiceProxyModule,
        SharedDlgModule,
        ClipboardModule,
        MatPaginatorModule,
        CKEditorModule,
        QuillModule.forRoot()
    ],
  declarations: [
    AppComponent,
    AppMobileHeaderComponent,
    AppAsideComponent,
    AppDashboardHeaderComponent,
    UsersComponent,
    DealDetailComponent,
    EditDealComponent,
    CreateDealComponent,
    CreateWalletDealComponent,
    MainPageComponent,
    DealsComponent,
    AppFooterComponent,
    AppWalletComponent,
    DlgSwitchNetworkComponent,
    DlgWalletComponent,
    DlgUnlockWalletComponent,
    DlgLockerPoolComponent,
    DlgContractProcessingComponent,
    DlgContractSubmittedComponent,
    DlgNotificationComponent,
    LoginPageComponent,
    DealViewerComponent,
    AdminsComponent,
    TiersPageComponent,
    StakingComponent,
    FarmingComponent,
    PoolDetailComponent,
    CreatePoolComponent,
    EditPoolComponent,
    PoolViewerComponent,
    PenaltiesComponent,
    SettingsComponent,
    ClaimingComponent,
    ClaimingDealDetailComponent,
    EditClaimingDealComponent,
    CreateClaimingDealComponent,
    UsersWalletComponent,
    UserDetailsComponent,
    DlgAddressBookComponent,
    UserPollsComponent,
    CreateUserPollComponent,
    EditUserPollComponent
  ],

  providers: [
    BrowserStorageProvider,
    UserSessionProvider,
    EventBus,
    Web3Service,
    PollsServiceProxy,
    DatePipe,
    { provide: API_BASE_URL, useValue: environment.remoteServiceBaseUrl }, // выставляем url web api для проксей
    { provide: AUTH_BASE_URL, useValue: environment.authServiceBaseUrl }, // выставляем url web api для проксей
    {
      provide: APP_INITIALIZER,
      useFactory: InitFactory,
      deps: [UserSessionProvider, Web3Service],
      multi: true,
    },
    DlgContractService,
    {
      provide : HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi   : true,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

function InitFactory(
  userSession: UserSessionProvider,
  web3Service: Web3Service
) {
  return async function (): Promise<void> {
    // Make right init things here
  };
}
