import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivate, UrlTree } from '@angular/router';
import { UserSessionProvider } from 'src/shared/user-session-provider';
import { menu } from 'src/app/app-aside/menu';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SuperAdminGuard implements CanActivate {
  constructor(private readonly userSessionProvider: UserSessionProvider, private readonly router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.userSessionProvider.isSuperAdmin;
  }
}
