<header>
  <button mat-button class="__close" [mat-dialog-close]>
    <mat-icon>close</mat-icon>
  </button>
</header>
<section>
  <div class='__icon'>
    <mat-icon>arrow_circle_up</mat-icon>
  </div>

  <p>Transaction submitted.</p>

  <a [href]="data?.tx | blockExplorerTxPipe: chainIdNumber" style="color:#fff;">
    {{ data?.tx | shortTx}}
  </a>
</section>
