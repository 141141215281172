import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imgNetworkPipe',
})
export class imgNetworkPipe implements PipeTransform {
  transform(chainId: number, isBlack: boolean = false): string {
    if (chainId == 56 || chainId == 97) {
      return '/assets/images/network/bsc.svg';
    }
    if (chainId == 1 || chainId == 42 || chainId == 5) {
      return '/assets/images/network/eth.svg';
    }
    if (chainId == 137 || chainId == 80001) {
      return '/assets/images/network/polygon.svg';
    }
    if (chainId == -100) {
      return '/assets/images/network/solana.svg';
    }
    if (chainId == -200) {
      return '/assets/images/network/tezos.svg';
    }
    if (chainId == 256 || chainId == 128) {
      return '/assets/svg/heco.svg';
    }
    if (chainId == 43114){
      return '/assets/images/network/avalanche.svg'
    }
    if (chainId == 250){
      return '/assets/svg/fantom.svg';
    }
    if (chainId == 321 || chainId == 322){
      return '/assets/images/network/kcc.png';
    }
    if (chainId == -9999100){
      return '/assets/svg/elrond.svg';
    }
    if (chainId == -9999101){
      return '/assets/svg/cardano.svg';
    }
    if (chainId == -9999103) {
      return '/assets/svg/polkadot.svg';
    }
    if (chainId == -9999104) {
        return '/assets/svg/cosmos.svg';
    }
    if (chainId == -9999105) {
        return '/assets/svg/flow.svg';
    }
    if (chainId == -9999106) {
        return '/assets/svg/near.svg';
    }
    if (chainId == 344435) {
        return '/assets/images/no-image.svg';
    }
    if (chainId == 888) {
        return '/assets/svg/wanchain.svg';
    }
    if (chainId == 1666600000) {
        return '/assets/svg/harmony.svg';
    }
    if (chainId == 66) {
      return '/assets/svg/okx.svg';
    }
    if (chainId == 42161) {
    return '/assets/svg/arbitrum.svg';
    }
    if (chainId == -9999107) {
  return '/assets/svg/Sui.svg';
    }
    if (chainId == -9999108) {
  return '/assets/svg/bitcoin.svg';
    }
    if (chainId == -9999109) {
  return '/assets/svg/aptos.svg';
    }
    if (chainId == 10) {
  return '/assets/svg/optimism.svg';
    }
    if (chainId == 8453) {
      return '/assets/svg/Base.svg';
    }
    if (chainId == 1284) {
      return '/assets/images/network/moonbeam.png';
    }
    if (chainId == 592) {
      return '/assets/images/network/Astar.png';
    }
    if (chainId == 1890) {
      return '/assets/images/network/Lightlink.png';
  }
    if (chainId == 81457) {
    return '/assets/svg/blast.svg';
  }
    return '/assets/images/no-image.svg';
  }
}
