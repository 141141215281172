<main class="dashboard-main">
  <section class="dashboard-section p-40">
    <form action="">
      <div class="row">
        <div class="col-xxl-6">
          <div class="card">
            <div class="card-header">
              <div class="subtitle-1">Warning Message</div>
            </div>
            <div class="card-content">
              <!-- TODO: Warning Message settings-->
              <mat-form-field appearance="fill" hideRequiredMarker class="mb-12">
                <mat-label>Message</mat-label>
                <textarea matInput name="message" rows="4" required></textarea>
              </mat-form-field>

              <!--<div class="form-floating mb-4">
                <input type="url" class="form-control" id="warning-link" placeholder="More link">
                <label for="warning-link">More Link</label>
              </div>-->

              <div class="d-flex align-items-center mt-3">
                <div class="subtitle-1 me-auto">Show</div>
                <mat-slide-toggle
                  name="showAlert"
                >
                </mat-slide-toggle>
              </div>
            </div>
            <div class="card-footer">
              <button type="submit" mat-raised-button color="primary" class="w-100">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

    </form>
  </section>
</main>
