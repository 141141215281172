import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import { Router } from '@angular/router';
import { UserSessionProvider } from 'src/shared/user-session-provider';
import { menu, MenuItem } from './menu';
import {Subscription} from "rxjs";

@Component({
  selector: 'app-aside',
  templateUrl: './app-aside.component.html',
  styleUrls: ['./app-aside.component.scss']
})
export class AppAsideComponent implements OnInit, OnDestroy {
  public menuItems: MenuItem[];
  public routerPath: string;
  private authSubscrition: Subscription;

  constructor(private router: Router, private userSessionProvider: UserSessionProvider,) {}

  ngOnInit(): void {
    this.authSubscrition = this.userSessionProvider.authChanged$.subscribe(()=>{
      this.filterMenus();
    });

    this.filterMenus();

    this.router.events.subscribe(value => {
      this.routerPath = this.router.url.split('?')[0];
    });

    const elementsArray = document.querySelectorAll('.sidenav__nav a.nav-link');

    elementsArray.forEach(function(elem) {
      elem.addEventListener('click', function() {
        document.body.classList.remove('mob-menu-active');
      });
    });
  }

  ngOnDestroy(): void {
    this.authSubscrition?.unsubscribe();
  }

  private filterMenus(): void {
    this.menuItems = menu.filter(i=>  (i.supportedChains.includes(this.userSessionProvider.getChainId()) || i.supportedChains.length == 0) && !i.disabled);
    this.menuItems = this.menuItems?.filter(i=> this.userSessionProvider.isSuperAdmin || !i.forSuperAdmin);
  }

  toggleSubMenu(event: any){
    const item = event.target.nextSibling;
    if (item.classList.contains('show')) {
      item.classList.remove('show');
    } else {
      item.classList.add('show');
    }
  }
}
