<main class="dashboard-main" [ngClass]="{'loading-page': waiting}">
  <mat-tab-group
    mat-align-tabs="start"
    class="fullpage-tabs"
    disableRipple>
    <mat-tab label="General">
      <div class="__deal-status" *ngIf="!isPaused">
        <div class="__status __status-unlocked">
          <i class="icomoon-key"></i>
          Claiming Deal Unlocked
        </div>
      </div>

      <div class="__deal-status" *ngIf="isPaused">
        <div class="__status __status-locked">
          <i class="icomoon-lock"></i>
          Claiming Deal Locked
        </div>
      </div>
      <div class="__deal-status" *ngIf="claimingDeal?.needsRecalculate">
        <div class="__status __status-warning">
          <mat-icon aria-hidden="false">warning_amber</mat-icon>
          New tokens arrived, please recalculate distribution
        </div>
      </div>

      <section class="dashboard-section p-40">
        <div class="__buttons white-card p-4 mb-40">
          <div class="btn-group-justified">
            <button mat-stroked-button class="btn-icon btn-icon-left" *ngIf="isPaused" (click)="updateLockClaimingDealClick(false)">
              <i class="icon icomoon-key"></i> Unlock Deal
            </button>

            <button mat-stroked-button class="btn-icon btn-icon-left" *ngIf="!isPaused" (click)="updateLockClaimingDealClick(true)">
              <i class="icon icomoon-lock"></i> Lock Deal
            </button>

            <button mat-raised-button color="primary" [disabled]="!isPaused || !claimingDeal?.needsRecalculate" (click)="recalculateClaimingDealClick()">Recalculate Distribution</button>
            <button mat-raised-button color="primary" [disabled]="!isPaused || (merkleRoot == this.claimingDeal?.merkleRoot)" (click)="updateMerkleRoot()">Update Merkle Root</button>
          </div>
        </div>

        <div class="__token white-card p-4 mb-40">
          <label>Merkle root</label>

          <div class="row">
            <div class="col-xl-6">
              <mat-form-field class="mb-3 mb-xl-0" appearance="fill">
                <mat-label>Merkle Root DB</mat-label>
                <input matInput type="text" disabled value="{{this.claimingDeal?.merkleRoot}}">
              </mat-form-field>
            </div>
            <div class="col-xl-6">
              <mat-form-field appearance="fill" hideRequiredMarker>
                <mat-label>Merkle Root Contract</mat-label>
                <input matInput type="text" disabled value="{{merkleRoot}}">
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="__token white-card p-4 mb-40">
          <label>Token</label>

          <div class="row">
            <div class="col-xl-6">
              <mat-form-field class="mb-3 mb-xl-0" appearance="fill">
                <mat-label>Total Tokens Distributed</mat-label>
                <input matInput type="number" disabled value="{{this.claimingDeal?.claimedAmount | shiftDecimals: -claimingDeal?.tokenDecimal| number}}">
              </mat-form-field>
            </div>
            <div class="col-xl-6">
              <mat-form-field appearance="fill" hideRequiredMarker>
                <mat-label>Total Tokens Received</mat-label>
                <input matInput type="number" disabled value="{{this.claimingDeal?.totalTokens | shiftDecimals: -claimingDeal?.tokenDecimal| number}}">
              </mat-form-field>
            </div>

            <div class="col-xl-6">
              <mat-form-field appearance="fill" hideRequiredMarker>
                <mat-label>Total Tokens to Distribute</mat-label>
                <input matInput type="number" disabled value="{{this.deal?.totalTokensToDistribute | shiftDecimals: -this.deal?.rewardDecimal| number}}">
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="__token white-card p-4 mb-40">
          <label>Token</label>

          <mat-form-field class="mb-3" appearance="fill">
            <mat-label>Token Name</mat-label>
            <input matInput type="text" value="{{this.rewardTokenSymbol}}" disabled>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Token Address</mat-label>
            <input matInput type="text" value="{{this.rewardToken}}" disabled>
          </mat-form-field>
        </div>

        <form #updateClaimingDealForm="ngForm" (ngSubmit)="updateClaimingDealDb()">
        <div class="__description white-card p-4 mb-40">
          <div class="row">
            <div class="col-xl-6">
              <mat-form-field appearance="fill" class="mb-3">
                <mat-label>Original Deal Page</mat-label>
                <mat-select disableOptionCentering [(ngModel)]="claimingDeal.dealAddress" name="dealAddress" (selectionChange)="updateDealFormValues($event.value)">
                  <mat-option *ngFor="let t of allDeals" [value]="t.dealAddress" matTooltip="{{t.dealAddress}}">
                    {{t.name}} / {{t.targetNetwork | networkName}} / {{t.dealType | dealTypeName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-xl-6">
              <mat-form-field appearance="fill"class="mb-3">
                <mat-label>Name</mat-label>
                <input matInput type="text" [(ngModel)]="name" name="name" required>
              </mat-form-field>
            </div>
          </div>

          <mat-form-field appearance="fill" class="mb-3">
            <mat-label>Tooltip Description For Locked Deal</mat-label>
            <input matInput type="text" [(ngModel)]="comment" name="comment" required>
          </mat-form-field>

          <mat-form-field appearance="fill" class="mb-3">
            <mat-label>Deal Name</mat-label>
            <input matInput type="text" disabled value="{{deal?.name}}">
          </mat-form-field>

          <div class="logo-upload mb-2">
            <mat-form-field appearance="fill" hideRequiredMarker class="logo-upload__input">
              <mat-label>Deal Address</mat-label>
              <input matInput type="url" disabled value="{{deal?.dealAddress}}">
            </mat-form-field>

            <div class="logo-upload__preview">
              <img [src]="deal?.logoURL?deal?.logoURL:'../../assets/images/no-image.svg'" alt="">
            </div>
          </div>
        </div>
        <section class="__submit mt-auto">
          <button type="submit" mat-raised-button color="primary" class="w-100" [disabled]="!updateClaimingDealForm.form.valid || waiting">Save Changes</button>
        </section>
      </form>
      </section>
    </mat-tab>

    <mat-tab label="Claiming Transfers">
      <table class="table table-responsive" >
        <thead>
        <tr>
          <th class="__date-col" >Date</th>
          <th >Amount</th>
          <th >TxId</th>
          <th class="__wallet-col">Sender</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of claimingTransferEvents; index as i">
          <td class="__date-col td-responsive td-responsive-full" data-title="Date">
            {{item.timestamp* 1000 | date:'dd MMM, yyyy H:mm a'}} (TS: {{item.timestamp}})
          </td>
          <td class="td-responsive td-responsive-full" data-title="Amount">
            {{item.amount| shiftDecimals: -claimingDeal?.tokenDecimal| number}}
            <!-- TODO: map isProcessed field once backend will update API -->
          </td>
          <td class="td-responsive td-responsive-full" data-title="TxId">
            <button class="__address address-btn --copy">
              <span [cdkCopyToClipboard]="item.tx">{{item.tx}}</span>
            </button>
          </td>
          <td class="td-responsive td-responsive-full" data-title="Sender">
            <div class="d-flex align-items-center justify-content-between">
              <button class="__address address-btn --copy">
                <span [cdkCopyToClipboard]="item.userWallet">{{item.userWallet}}</span>
              </button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </mat-tab>


    <mat-tab label="User Current proofs">
      <table class="table table-responsive" >
        <thead>
        <tr>
          <th class="__date-col" >Date</th>
          <th >Amount</th>
          <th >isClaimed</th>
          <th class="__wallet-col">User</th>
          <th class="__wallet-col">Emission</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of claimingUserEvents; index as i">
          <td class="__date-col td-responsive td-responsive-full" data-title="Date">
            {{item.timestamp* 1000 | date:'dd MMM, yyyy H:mm a'}} (TS: {{item.timestamp}})
          </td>
          <td class="td-responsive td-responsive-full" data-title="Amount">
            {{item.index}} - {{item.amount| shiftDecimals: -claimingDeal?.tokenDecimal| number}}
          </td>
          <td class="td-responsive td-responsive-full" data-title="isClaimed">
            {{item.isClaimed}}
          </td>
          <td class="td-responsive td-responsive-full" data-title="UserWallet">
            <div class="d-flex align-items-center justify-content-between">
              <button class="__address address-btn --copy">
                <span [cdkCopyToClipboard]="item.userWallet">{{item.userWallet}}</span>
              </button>
            </div>
          </td>
          <td class="td-responsive td-responsive-full" data-title="Emission">
            <div class="d-flex align-items-center justify-content-between">
              <button class="__address address-btn --copy">
                <span [cdkCopyToClipboard]="item.emissionAddress">{{item.emissionAddress}}</span>
              </button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </mat-tab>
  </mat-tab-group>
</main>
