import {environment} from 'src/environments/environment';

export interface MenuItem {
  readonly label: string,
  readonly routePath: string[] | string,
  readonly supportedChains: number[],
  readonly icon?: string;
  readonly childItems?: MenuItem[],
  readonly isExternal?: boolean;
  readonly disabled?: boolean;
  readonly forSuperAdmin?: boolean;
}

export const menu: MenuItem[] = [
  {
    icon: "icomoon-users",
    label: "Users",
    routePath: "/users",
    supportedChains: environment.supportedChains,
  },
  {
    icon: "icomoon-users",
    label: "Admins",
    routePath: "/admins",
    supportedChains: environment.supportedChains,
  },
  {
    icon: "icomoon-chart-down",
    label: "Penalties",
    routePath: "/penalties",
    disabled: true,
    supportedChains: environment.supportedChains,
    forSuperAdmin: true
  },
  {
    label: "Tiers",
    icon: "icomoon-tiers",
    routePath: ["/tiers"],
    supportedChains: [56, 97, 1, 5],
    forSuperAdmin: true
  },
  {
    label: "Deals",
    icon: "icomoon-claim",
    routePath: ["/deals", `/create-wallet-deal`, "/deal-detail"],
    supportedChains: environment.supportedChains,
    forSuperAdmin: true,
    childItems: [{
      label: "Deals",
      routePath: `/deals`,
      supportedChains: environment.supportedChains,
    },
      {
        label: "Create Deal",
        routePath: `/create-wallet-deal`,
        supportedChains: [56, 97, 1, 5],
      },]
  },
  // {
  //   label: "Pools",
  //   icon: "icomoon-pools",
  //   routePath: ["/staking", `/farming`, `/create-pool`, `/pool-detail`, "/edit-pool"],
  //   supportedChains: environment.supportedChains,
  //   forSuperAdmin: true,
  //   childItems: [{
  //     label: "Staking Pools",
  //     routePath: `/staking`,
  //     supportedChains: environment.supportedChains,
  //   },
  //     {
  //       label: "Farming Pools",
  //       routePath: `/farming`,
  //       supportedChains: environment.supportedChains,
  //     },
  //     {
  //       label: "Create Pool",
  //       routePath: `/create-pool`,
  //       supportedChains: environment.supportedChains,
  //     },]
  // },
  {
    icon: 'icomoon-info',
    label: 'Voting',
    routePath: '/voting',
    supportedChains: environment.supportedChains,
    forSuperAdmin: true
  },
  {
    label: "Settings",
    icon: "icomoon-warning",
    routePath: "/settings",
    supportedChains: environment.supportedChains,
    disabled: true,
    forSuperAdmin: true
  },
];
