import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { ComponentBase } from '../../shared/component-base';
import { EventBus } from '../../shared/event-bus';
import { Web3Service } from '../../shared/web3-service';
import BigNumber from 'bignumber.js';
import { DlgSwitchNetworkComponent } from '../dlg-switch-network';

import { throwError, merge } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { DlgContractService } from '../dlg-contract.service';

import { AlertService } from '../shared-dlg.module';

import { environment } from '../../environments/environment';
import networks from '../networks.data';
import { DlgUnlockWalletComponent } from '../dlg-unlock-wallet';
import { UserSessionProvider } from '../../shared/user-session-provider';
import { ActivatedRoute, Router } from '@angular/router';
import { DealDTO, DealRegistrationDTO, DealServiceProxy, UserDTO, UsersServiceProxy } from '../../service-proxies/service-proxies';
declare const window: any;

@Component({
  selector: 'deal-detail',
  templateUrl: './deal-detail.component.html',
  styleUrls: ['./deal-detail.component.scss'],
})
export class DealDetailComponent extends ComponentBase implements OnInit, OnDestroy {
  constructor(
    private _dialog: MatDialog,
    private eventBus: EventBus,
    private web3Service: Web3Service,
    private userSessionProvider: UserSessionProvider,
    private route: ActivatedRoute,
    private router: Router,
    private dealService: DealServiceProxy,
    private usersService: UsersServiceProxy
  ) {
    super();

    this.now = Math.floor(Date.now() / 1000);

    this.updateTimeTimerId = setInterval(() => {
      this.now = Math.floor(Date.now() / 1000);

      var diffStart = 0
      if (this.isUpcoming)
        var diffStart = this.startTimestamp - this.now;
      else if (this.isStarted)
        var diffStart = this.finishTimestamp - this.now;
      if (diffStart > 0) {
        this.timerViewDays = Math.floor(diffStart / (3600 * 24));
        this.timerViewHours = Math.floor(diffStart % (3600 * 24) / 3600);
        this.timerViewMin = Math.floor(diffStart % 3600 / 60);
        this.timerViewSec = Math.floor(diffStart % 60);
      }
      else {
        this.timerViewDays = 0;
        this.timerViewHours = 0;
        this.timerViewMin = 0;
        this.timerViewSec = 0;
      }
    }, 1000);
  }

  isCollectWalletDeal: boolean = false;
  deal: DealDTO;
  userInfo: UserDTO;
  dealRegistration: DealRegistrationDTO;

  kycConfirmed: boolean = false;

  waiting: boolean = false;
  account: string = '';
  dealAddress: string = '';

  //#region contract data
  paymentToken: string;

  tokenPrice: string;
  rewardToken: string;
  rewardDecimals: number;
  startTimestamp: number;
  finishTimestamp: number;
  startClaimTimestamp: number;
  maxDistributedTokenAmount: string;
  totalRaise: string; // Sum of all payments (in payment token)
  tokensForDistribution: string;
  leftDistributedTokenAmount: number;
  minimumRaise: string;
  distributedTokens: string;
  allowRefund: boolean;

  vestingContractAddress: string;
  vestingPercent: number;
  vestingStart: number;
  vestingInterval: number;
  vestingDuration: number;

  //#end region contract data
  paymentDecimal: number;
  paymentTokenSymbol: string;
  rewardTokenSymbol: string;
  rewardTokenName: string;
  tokensPerETH: number;

  userTier: any;
  userTierIndex: number = -1;
  allTiers: any[] = new Array();


  updateTimeTimerId: NodeJS.Timeout;
  updateUserTimerId: NodeJS.Timeout;
  updateDealTimerId: NodeJS.Timeout;

  now: number;

  timerViewDays: number;
  timerViewHours: number;
  timerViewMin: number;
  timerViewSec: number;


  usersDebtAmount: number;
  usersTotalPaymentAmount: number;
  usersClaimedTokensAmount: number;
  usersReleasableAmount: number;
  usersVestedAmount: number;
  usersVestedReleasedAmount: number;

  public get allowClaim(): boolean {
    if (!this.startClaimTimestamp || this.startClaimTimestamp == 0)
      return false;
    return this.now > this.startClaimTimestamp;
  }

  public get isUpcoming(): boolean {
    return this.now < this.startTimestamp;
  }

  public get isStarted(): boolean {
    return this.now > this.startTimestamp && this.now < this.finishTimestamp;
  }

  public get isFinished(): boolean {
    return this.now > this.finishTimestamp;
  }

  public get allowPay(): boolean {
    if (!this.startTimestamp)
      return false;
    if (!this.finishTimestamp)
      return false;

    return this.now > this.startTimestamp && this.now < this.finishTimestamp;
  }

  public get getDistributedPercent(): number {
    if (this.maxDistributedTokenAmount && this.tokensForDistribution)
      return new BigNumber(this.tokensForDistribution).div(this.maxDistributedTokenAmount).multipliedBy(100).toNumber();
    return 0;
  }
  public get getDistributedLeftAmount(): number {
    if (this.maxDistributedTokenAmount && this.tokensForDistribution) {
      return new BigNumber(this.maxDistributedTokenAmount).shiftedBy(-this.paymentDecimal).toNumber() - new BigNumber(this.tokensForDistribution).shiftedBy(-this.paymentDecimal).toNumber();
    }
    return 0;
  }
  public getDistributedPercentTier(maxDistributedTokenAmountTier: number, tokensForDistributionTier: number): number {
    if (maxDistributedTokenAmountTier && tokensForDistributionTier)
      return new BigNumber(tokensForDistributionTier).div(maxDistributedTokenAmountTier).multipliedBy(100).toNumber();
    return 0;
  }
  public getTotalRaiseETH(deal: DealDTO): number {
    return new BigNumber(deal.maxDistributedTokenAmount).shiftedBy(-deal.rewardDecimal).multipliedBy(deal.tokenPrice).shiftedBy(-deal.paymentDecimal).toNumber();
  }
  async ngOnInit() {
    this.route
      .queryParams
      .subscribe(params => {
        this.dealAddress = params['address'];
        console.log(`deal address: ${this.dealAddress}`);
        this.dealService.getByAddress(this.dealAddress).subscribe(result => {
          this.deal = result;
          this.isCollectWalletDeal = this.deal.isDealWithoutDistribution;
          this.paymentTokenSymbol = result.paymentTokenSymbol;
          console.log(this.deal);
        },
          error => {
            this.processServiceError(error);
          });
      });

    if (this.userSessionProvider.isAuthorized) {
      //this.usersService.getMe()
      //  .subscribe(
      //    result => {
      //      this.userInfo = result;
      //      this.kycConfirmed = result.kycConfirmed;
      //    },
      //    error => {
      //      this.processServiceError(error);
      //    }
      //);

      //this.dealService.getRegistration(this.dealAddress)
      //  .subscribe(
      //    result => {
      //      this.dealRegistration = result;
      //    },
      //    error => {
      //      this.processServiceError(error);
      //    }
      //  );
    }

    await this.web3Service.initWeb3();
    if (this.web3Service.web3) {
      this.updateDealContractData();

      this.updateDealDataInterval();
      this.updateDealTimerId = setInterval(() => {
        this.updateDealDataInterval();
      }, this.expectedBlockTime);
    }

    this.eventBus.loginEvent.subscribe(result => {
      console.log('loginEvent subscription:' + result);
      this.eventLogin(result);
    });

    this.eventBus.logoutEvent.subscribe(result => {
      console.log('logoutEvent subscription:' + result);
      this.eventLogout();
    });
  }


  async eventLogin(username: string) {
    console.log('eventLogin');
    console.log(username);
    this.account = username;

    //TODO:HACK call early than updateDealContractData
    this.rewardDecimals = parseInt(await this.web3Service.getDealDecimals(this.dealAddress));
    this.paymentToken = await this.web3Service.getDealPaymentToken(this.dealAddress);
    if (this.paymentToken != "0x0000000000000000000000000000000000000000") {
      this.paymentDecimal = parseInt(await this.web3Service.GetDecimals(this.paymentToken));
    }
    else {
      this.paymentDecimal = 18;
    }

    this.updateUserData();

    this.updateUserTimerId = setInterval(() => {
      this.updateUserData();
    }, this.expectedBlockTime);

    //this.getTokensDebts(username);
  }

  eventLogout(): void {
    console.log('signOut')
    this.account = "";

    //this.tokenDebt = 0;
    //this.totalInvestedETH = 0;
    //this.ethBalance = 0;
    //this.totalStakeETH = null;
    //this.totalBuyToken = null;
    //this.myClaimedTokensAmount = null;
    //this.tokensDebt = new Array();

    if (this.updateUserTimerId) {
      clearInterval(this.updateUserTimerId);
    }
  }

  async payClick() {
    //this.waiting = true;

    ////const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

    //const contractEventsSource = this.web3Service.dealPay(this.dealAddress, this.account, this.userTier.ticketSize,
    //  this.dealRegistration.signature, this.deal.paymentToken == '0x0000000000000000000000000000000000000000');

    //contractEventsSource.transactionHash$
    //  .subscribe(val => {
    //    this.waiting = false;
    //    this._dialog.closeAll();
    //    console.log(`transactionHash$ ${val}`);
    //    //this._dlgContractSrv.showSubmitted({ tx: val });
    //    this._alertSrv.show('Transaction submitted.');
    //  });

    //try {
    //  await contractEventsSource.receipt$.toPromise();

    //  //dialogRef.close();
    //  this._alertSrv.show('Confirmed transaction');
    //  this.updateUserData();
    //} catch (err) {
    //  //dialogRef.close();
    //  console.info('catch');
    //  console.info(err);
    //}
    //this.waiting = false;
  }

  async claimClick() {
    this.waiting = true;

    //const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

    const contractEventsSource = this.web3Service.dealClaim(this.dealAddress, this.account);

    contractEventsSource.transactionHash$
      .subscribe(val => {
        this.waiting = false;
        this._dialog.closeAll();
        console.log(`transactionHash$ ${val}`);
        //this._dlgContractSrv.showSubmitted({ tx: val });
        this.showSuccessModal('Transaction submitted.');
      });

    try {
      await contractEventsSource.receipt$.toPromise();

      //dialogRef.close();
      this.showSuccessModal('Confirmed transaction');
      this.updateUserData();
    } catch (err) {
      //dialogRef.close();
      console.info('catch');
      console.info(err);
    }
    this.waiting = false;
  }


  async claimVesingClick() {
    this.waiting = true;

    //const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

    const contractEventsSource = this.web3Service.vestingRelease(this.vestingContractAddress, this.account);

    contractEventsSource.transactionHash$
      .subscribe(val => {
        this.waiting = false;
        this._dialog.closeAll();
        console.log(`transactionHash$ ${val}`);
        //this._dlgContractSrv.showSubmitted({ tx: val });
        this.showSuccessModal('Transaction submitted.');
      });

    try {
      await contractEventsSource.receipt$.toPromise();

      //dialogRef.close();
      this.showSuccessModal('Confirmed transaction');
      this.updateUserData();
    } catch (err) {
      //dialogRef.close();
      console.info('catch');
      console.info(err);
    }
    this.waiting = false;
  }

  async updateDealContractData() {

    console.log('Get contract data')
    this.paymentToken = await this.web3Service.getDealPaymentToken(this.dealAddress);
    if (this.paymentToken != "0x0000000000000000000000000000000000000000") {
      this.web3Service.GetContractSymbol(this.paymentToken).then((resp) => {
        this.paymentTokenSymbol = resp;
      });
      this.paymentDecimal = parseInt(await this.web3Service.GetDecimals(this.paymentToken));
    }
    else {
      //this.paymentTokenSymbol = get from DTO
      this.paymentDecimal = 18;
    }
    this.tokenPrice = await this.web3Service.getDealTokenPrice(this.dealAddress);
    this.rewardToken = await this.web3Service.getDealRewardToken(this.dealAddress);

    //TODO: if another chain reward token will be empty
    if (this.rewardToken) {
      this.web3Service.GetContractSymbol(this.rewardToken).then((resp) => {
        this.rewardTokenSymbol = resp;
      });
      this.web3Service.GetContractName(this.rewardToken).then((resp) => {
        this.rewardTokenName = resp;
      });
    }

    if (this.isCollectWalletDeal) {
      this.rewardDecimals = 18;
    }
    else {
      this.rewardDecimals = parseInt(await this.web3Service.getDealDecimals(this.dealAddress));
    }
    this.tokensPerETH = new BigNumber(1).shiftedBy(this.paymentDecimal).dividedBy(this.tokenPrice).toNumber();

    this.startTimestamp = parseInt(await this.web3Service.getDealStartTimestamp(this.dealAddress));
    this.finishTimestamp = parseInt(await this.web3Service.getDealFinishTimestamp(this.dealAddress));
    this.startClaimTimestamp = parseInt(await this.web3Service.getDealStartClaimTimestamp(this.dealAddress));

    this.web3Service.getDealMaxDistributedTokenAmount(this.dealAddress).then((resp) => {
      this.maxDistributedTokenAmount = resp;
    });



    this.web3Service.getDealMinimumRaise(this.dealAddress).then((resp) => {
      this.minimumRaise = resp;
    });

    this.web3Service.getDealAllowRefund(this.dealAddress).then((resp) => {
      this.allowRefund = Boolean(resp);
    });


    let tiesrLenght = parseInt(await this.web3Service.getDealTiersLength(this.dealAddress));
    for (let i = 0; i < tiesrLenght; i++) {
      let tier = await this.web3Service.getDealTiers(this.dealAddress, i);
      console.log(tier);
      this.allTiers.push(tier);
    }

    this.web3Service.getVestingPercent(this.dealAddress).then((resp) => {
      this.vestingPercent = parseInt(resp);
    });

    let vestAddress = await this.web3Service.getDealVestingAddress(this.dealAddress);
    this.vestingContractAddress = vestAddress == "0x0000000000000000000000000000000000000000" ? null : vestAddress;

    if (this.vestingContractAddress) {

      this.web3Service.getVVestingStart(this.vestingContractAddress).then((resp) => {
        this.vestingStart = parseInt(resp);
      });

      this.web3Service.getVVestingInterval(this.vestingContractAddress).then((resp) => {
        this.vestingInterval = parseInt(resp);
      });

      this.web3Service.getVVestingDuration(this.vestingContractAddress).then((resp) => {
        this.vestingDuration = parseInt(resp);
      });
    }
  }

  async updateDealDataInterval() {
    this.web3Service.getDealTokensForDistribution(this.dealAddress).then((resp) => {
      this.tokensForDistribution = resp;
    });

    this.web3Service.getDealTotalRaise(this.dealAddress).then((resp) => {
      this.totalRaise = resp;
    });

    this.web3Service.getDealDistributedTokens(this.dealAddress).then((resp) => {
      this.distributedTokens = resp;
    });
  }

  async updateUserData() {
    console.log('updateUserData');
    //Check if deal without distribution
    let rewardTokenAddress = await this.web3Service.getDealRewardToken(this.dealAddress)
    if (!rewardTokenAddress) {
      this.rewardDecimals = 18;
    }

    this.web3Service.getDealUserInfo(this.dealAddress, this.account).then((userInfo) => {
      console.log(userInfo);
      console.log('userInfo');
      this.usersDebtAmount = this.toNumberFromWei(userInfo.debt, this.rewardDecimals);
      this.usersTotalPaymentAmount = this.toNumberFromWei(userInfo.totalPayment, this.paymentDecimal);
      this.usersClaimedTokensAmount = this.toNumberFromWei((new BigNumber(userInfo.total)).minus(userInfo.debt).toString(), this.rewardDecimals);
    });

    if (this.vestingContractAddress) {
      this.web3Service.getVestingReleasableAmount(this.vestingContractAddress, this.account).then((resp) => {
        this.usersReleasableAmount = this.toNumberFromWei(resp, this.rewardDecimals);
      });

      this.web3Service.getVestingForUser(this.vestingContractAddress, this.account).then((resp) => {
        this.usersVestedAmount = this.toNumberFromWei(resp[0], this.rewardDecimals);
        this.usersVestedReleasedAmount = this.toNumberFromWei(resp[1], this.rewardDecimals);
      });
    }


    this.web3Service.getDealUsersTierIndex(this.dealAddress, this.account).then(async (resp) => {
      //TODO: check response success
      console.log('getDealUsersTierIndex');
      console.log(resp);
      if (Boolean(resp[0])) {
        this.userTierIndex = parseInt(resp[1])
        this.userTier = await this.web3Service.getDealTiers(this.dealAddress, this.userTierIndex);
      }
      else {
        console.error(resp);
        console.error(`Tier not found for user: ${this.account}`)
      }
    });
  }


  joinDealClick() {
    //if (this.userSessionProvider.isAuthorized) {
    //  if (this.kycConfirmed) {
    //    this.dealService.registrate(this.dealAddress).subscribe(result => {
    //      this.dealRegistration = result;
    //    },
    //      error => {
    //        this.processServiceError(error)
    //      });
    //  }
    //  else {
    //    this.navigateToKYC();
    //  }
    //}
    //else {
    //  this.navigateToLogin();
    //}
  }

  processServiceError(error: any) {
    console.error(error);
    if (error.status == 401) {
      console.error('401');
      this.userSessionProvider.finishAuth();
      this.navigateToLogin();
    }
    else
      this.showErrorModal(JSON.parse(error.response).message);
  }

  navigateToLogin(): void {
    this.router.navigate(["/login"]);
  }

  navigateToKYC(): void {
    this.router.navigate(["/kyc"]);
  }
  navigateToEditDeal() {
    this.router.navigate(['/edit-deal'], { queryParams: { address: this.dealAddress } });
  }
  public addToGoogleCalendar() {
    const startDate = new Date(this.startTimestamp * 1000).toISOString().replace(/-|:|\.\d\d\d/g,'');
    const finishDate = new Date(this.startTimestamp * 1000).toISOString().replace(/-|:|\.\d\d\d/g,'');
    const url = new URL('https://www.google.com/calendar/render');
    const eventInfo = 'Deal details: <a href="https://app.bullperks.com/deal-detail?address=' + this.dealAddress + '">' +
      'https://app.bullperks.com/deal-detail?address=' + this.dealAddress + '</a>';
    url.searchParams.append('action', 'TEMPLATE');
    url.searchParams.append('text', this.deal.name + ' Deal on BullPerks');
    url.searchParams.append('dates', startDate + '/' + finishDate);
    url.searchParams.append('details', eventInfo);
    url.searchParams.append('sf', 'true');
    url.searchParams.append('output', 'xml');

    window.open(url.toString(), '_blank').focus();
  }

  async ngOnDestroy() {
    if (this.updateUserTimerId) {
      clearInterval(this.updateUserTimerId);
    }
    if (this.updateDealTimerId) {
      clearInterval(this.updateDealTimerId);
    }
    if (this.updateTimeTimerId) {
      clearInterval(this.updateTimeTimerId);
    }
  }

  scrollToDescription() {
    document.getElementById('deal-about').scrollIntoView();
  }
}
