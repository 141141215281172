import { NgModule } from '@angular/core';
import { BlockExplorerAddressPipe } from './blockExplorerAddressPipe.pipe';
import { BlockExplorerTxPipe } from './blockExplorerTxPipe.pipe';
import { ImgBlackSymbolPipe } from './imgBlackSymbolPipe.pipe';
import { ImgChainIdPipe } from './imgChainIdPipe.pipe';
import { imgNetworkPipe } from './imgNetworkPipe.pipe';
import { ImgSymbolPipe } from './imgSymbolPipe.pipe';
import { NameTierPipe } from './nameTierPipe.pipe';
import { DealTypeNamePipe } from './dealTypeName.pipe';
import { ImgTierPipe } from './imgTierPipe.pipe';
import { NetworkFullName } from './networkFullName.pipe';
import { NetworkNamePipe } from './networkName.pipe';
import { NetworkSymbolPipe } from './networkSymbol.pipe';
import { ShiftDecimals } from './shiftDecimals.pipe';
import { ShortAddressPipe } from './shortAddress.pipe';
import { ShortTxPipe } from './shortTx.pipe';
import { ShowPeriod } from './showPeriod.pipe';
import { YesNoPipe } from './YesNo.pipe';
import {SafeHtmlPipe} from "./safe-html.pipe";

@NgModule({
  exports: [
    ShortAddressPipe,
    ShortTxPipe,
    YesNoPipe,
    ShiftDecimals,
    NetworkSymbolPipe,
    NetworkNamePipe,
    NetworkFullName,
    ImgSymbolPipe,
    ImgBlackSymbolPipe,
    ImgChainIdPipe,
    BlockExplorerTxPipe,
    BlockExplorerAddressPipe,
    ShowPeriod,
    NameTierPipe,
    ImgTierPipe,
    imgNetworkPipe,
    DealTypeNamePipe,
    SafeHtmlPipe
  ],
  declarations: [
    ShortAddressPipe,
    ShortTxPipe,
    YesNoPipe,
    ShiftDecimals,
    NetworkSymbolPipe,
    NetworkNamePipe,
    NetworkFullName,
    ImgSymbolPipe,
    ImgBlackSymbolPipe,
    ImgChainIdPipe,
    BlockExplorerTxPipe,
    BlockExplorerAddressPipe,
    ShowPeriod,
    NameTierPipe,
    ImgTierPipe,
    imgNetworkPipe,
    DealTypeNamePipe,
    SafeHtmlPipe
  ],
  providers: [
  ]
})
export class PipesModule {
}
