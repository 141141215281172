<main class="dashboard-main">
  <section class="deals-section">

    <ul class="nav nav-tabs nav-tabs-filled deals-type-tab"
        id="dealsTypeTab"
        role="tablist">
      <li class="nav-item">
        <button class="nav-link"
                [ngClass]="{'active':(dealTypeFilter == 0)}"
                type="button"
                (click)="selectVC()">
          VC
        </button>
      </li>
      <li class="nav-item">
        <button class="nav-link"
                [ngClass]="{'active':(dealTypeFilter == 1)}"
                type="button"
                (click)="selectIDO()">
          IDO
        </button>
      </li>
      <li class="nav-item">
        <button class="nav-link"
                [ngClass]="{'active':(dealTypeFilter == 2)}"
                type="button"
                (click)="selectINO()">
          INO
        </button>
      </li>
    </ul>

    <div class="__deals-list">
      <mat-tab-group
        mat-align-tabs="start"
        class="fullpage-tabs deals-tabs"
        selectedIndex="4"
        disableRipple>
        <mat-tab label="Upcoming">
          <mat-spinner *ngIf="waitingDeals" class="__loading" diameter="40"></mat-spinner>

          <div class="card not-found" *ngIf="!waitingDeals && upcomingDeals.length == 0">
            <div class="icon icomoon-box"></div>
            <div class="not-found__text">No upcoming deals for now</div>
          </div>

          <div class="card-list per-row-2">
            <div class="deal-card card cursor-pointer" *ngFor="let item of upcomingDeals; index as i">
              <deal-viewer [item]="item"></deal-viewer>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="In Progress">
          <mat-spinner *ngIf="waitingDeals" class="__loading" diameter="40"></mat-spinner>

          <div class="card not-found" *ngIf="!waitingDeals && inProgressDeals.length == 0">
            <div class="icon icomoon-box"></div>
            <div class="not-found__text">No deals in progress for now</div>
          </div>

          <div class="card-list per-row-2">
            <div class="deal-card card cursor-pointer" *ngFor="let item of inProgressDeals; index as i">
              <deal-viewer [item]="item"></deal-viewer>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="Ended">
          <mat-spinner *ngIf="waitingDeals" class="__loading" diameter="40"></mat-spinner>

          <div class="card not-found" *ngIf="!waitingDeals && endedDeals.length == 0">
            <div class="icon icomoon-box"></div>
            <div class="not-found__text">No finished deals yet</div>
          </div>
          <div class="card-list per-row-2">
            <div class="deal-card card cursor-pointer" *ngFor="let item of endedDeals; index as i">
              <deal-viewer [item]="item"></deal-viewer>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="All">
          <mat-spinner *ngIf="waitingDeals" class="__loading" diameter="40"></mat-spinner>

          <div class="card not-found" *ngIf="!waitingDeals && allDealsFiltered.length == 0">
            <div class="icon icomoon-box"></div>
            <div class="not-found__text">No deals yet</div>
          </div>

          <div class="card-list per-row-2">
            <div class="deal-card card cursor-pointer" *ngFor="let item of allDealsFiltered; index as i">
              <deal-viewer [item]="item"></deal-viewer>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </section>
</main>
