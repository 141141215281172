import {Component, OnInit, ViewChild} from '@angular/core';
import {PollsServiceProxy} from '../../service-proxies/service-proxies';
import {ComponentBase} from '../../shared/component-base';
import {ActivatedRoute, Router} from '@angular/router';
import {QuillEditorComponent, QuillModules} from 'ngx-quill';
import Editor from "ckeditor5-custom-build/build/ckeditor";
import {CKEditor5} from "@ckeditor/ckeditor5-angular";


@Component({
  selector: 'app-edit-user-poll',
  templateUrl: './edit-user-poll.component.html',
  styleUrls: ['./edit-user-poll.component.scss']
})

export class EditUserPollComponent extends ComponentBase implements OnInit {
  @ViewChild('quillEditor') quillEditor: QuillEditorComponent;
  constructor(private pollsService: PollsServiceProxy, private router: Router, private route: ActivatedRoute) {
    super();
  }

  public Editor: any = Editor;
  public config: CKEditor5.Config = {
    htmlSupport: {
      allow: [
        {
          name: /.*/,
          attributes: true,
          classes: true,
          styles: true
        }
      ]
    },
    link: {
      defaultProtocol: 'http://'
    },
    mediaEmbed: {
      previewsInData: true
    }
  }

  pollId: string;
  userPoll: any;

  isVisible = false;
  title: string;
  description: string;
  leadingButtonLink: string;
  leadingButtonTitle: string;
  contentInput: string;

  options: any = [
    {
      id: 1,
      option: 'Yes',
      editing: false
    },
    {
      id: 2,
      option: 'No',
      editing: false
    }
  ];

  addingOption = false;
  newOption = '';
  editor: any;

  quillStyles = {
    'min-height': '100px'
  };
  quillConfig: QuillModules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'link'],
      [{ list: 'ordered'}, { list: 'bullet' }],
      ['clean'],
      ['image'],
    ],
  };



  ngOnInit(): void {
    this.route
      .queryParams
      .subscribe(params => {
        this.pollId = params.pollId;
        this.getUserPollById();
      });
  }

  handleImage(): void {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.onchange = () => {
      const file = input.files[0];
      const maxSize = 2 * 1024 * 1024; // 2 MB
      if (file && file.size > maxSize) {
        alert(`Image size exceeds the maximum allowed size of 2 MB`);
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          const range = this.editor.getSelection(true);
          const index = range.index + range.length;
          this.editor.insertEmbed(index, 'image', reader.result);
        };
        reader.readAsDataURL(file);
      }
    };
    input.click();
  }

  getEditorInstance(handler: QuillEditorComponent): any {
    this.editor = handler;
    const toolbar = this.editor.getModule('toolbar');
    const image = toolbar?.addHandler('image', () => {
      this.handleImage();
    });
    image.handler = this.handleImage.bind(this);
  }

  async getUserPollById(): Promise<void> {
    this.userPoll = await this.pollsService.getPollById(this.pollId).toPromise();
    this.isVisible = this.userPoll.isVisible;
    this.title = this.userPoll.title;
    this.description = this.userPoll.description;
    this.options = this.userPoll.options;
  }

  editOption(option: any): void {
    option.editedOption = option.option;
    option.editedContentInput = option.contentInput;
    option.editedLeadingButtonTitle = option.leadingButtonTitle;
    option.editedLeadingButtonLink = option.leadingButtonLink;
    option.editing = true;
  }

  saveOption(option: any): void {
    option.editing = false;
    option.option = option.editedOption;
    option.contentInput = option.editedContentInput;
    option.leadingButtonTitle = option.editedLeadingButtonTitle;
    option.leadingButtonLink = option.editedLeadingButtonLink;
  }

  removeOption(option: any): void {
    const index = this.options.indexOf(option);
    if (index >= 0) {
      this.options.splice(index, 1);
    }
  }


  saveNewOption(): void {
    const newOption = {
      option: this.newOption,
      contentInput: this.contentInput,
      leadingButtonLink: this.leadingButtonLink,
      leadingButtonTitle: this.leadingButtonTitle,
    };
    this.options.push(newOption);
    this.addingOption = false;
    this.newOption = '';
  }

  updatePoll(): void {
    this.options.every((option: any) => {
      delete option.editing;
      delete option.editedOption;
      delete option.editedContentInput;
      delete option.editedLeadingButtonTitle;
      delete option.editedLeadingButtonLink;
      return true;
    });
    delete this.userPoll.votes;
    const formValues = {
      ...this.userPoll,
      isVisible: this.isVisible,
      title: this.title,
      description: this.description,
      options: this.options,
    };
    this.pollsService.updatePoll(formValues).subscribe(
      res => {
        this.router.navigate(['/voting']);
      },
      error => {
        console.log(error);
      }
    );
  }

  cancelPollUpdate(): void {
    this.router.navigate(['/voting']);
  }

  addHttpsToLinks(editor: QuillEditorComponent): any {
    const links = editor.quillEditor.root.getElementsByTagName('a');
    for (let i = 0; i < links.length; i++) {
      if (!links[i].attributes.href.value.startsWith('https:')) {
        if (links[i].attributes.href.value.startsWith('http://')) {
          links[i].href = links[i].attributes.href.value;
        }else {
          links[i].href = 'https://' + links[i].attributes.href.value;
        }
      }
    }
  }

}
