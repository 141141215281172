import {Component, OnInit} from '@angular/core';
import {ComponentBase} from '../../shared/component-base';
import {EventBus} from '../../shared/event-bus';
import {Web3Service} from '../../shared/web3-service';
import {UserSessionProvider} from '../../shared/user-session-provider';
import {
  ClaimingDealDTO,
  ClaimingDealServiceProxy, ClaimingDealTransferDTO,
  ClaimingDealUpdateDTO, ClaimingDealUserDetailsDTO,
  DealDTO,
  DealServiceProxy
} from '../../service-proxies/service-proxies';
import {ActivatedRoute, Router} from '@angular/router';
import {NetworkNamePipe} from '../../shared/pipes/networkName.pipe';

@Component({
  selector: 'edit-claiming-deal',
  templateUrl: './edit-claiming-deal.component.html',
  styleUrls: ['./edit-claiming-deal.component.scss']
})
export class EditClaimingDealComponent extends ComponentBase implements OnInit {

  constructor(
    private eventBus: EventBus,
    private web3Service: Web3Service,
    private userSessionProvider: UserSessionProvider,
    private claimingDealServiceProxy: ClaimingDealServiceProxy,
    private dealService: DealServiceProxy,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super();
  }
  waiting: boolean = false;
  account: string = '';
  claimingDealAddress: string;
  claimingDeal: ClaimingDealDTO;
  updateTimeTimerId: NodeJS.Timeout;
  now: number;

  rewardToken: string;
  rewardDecimal: number;
  rewardTokenSymbol: string;
  isPaused: boolean;
  merkleRoot: string;

  name:string;
  comment:string;
  totalTokens: string;
  deal: DealDTO;
  public allDeals: Array<DealDTO> = [];
  claimingTransferEvents: Array<ClaimingDealTransferDTO> = [];
  claimingUserEvents: Array<ClaimingDealUserDetailsDTO> = [];

  async ngOnInit() {
    this.now = Math.floor(Date.now() / 1000);

    this.updateTimeTimerId = setInterval(() => {
      this.now = Math.floor(Date.now() / 1000);

    }, 1000);

    await this.web3Service.initWeb3();

    this.route
      .queryParams
      .subscribe(params => {
        this.claimingDealAddress = params['address'];
        console.log(`deal address: ${this.claimingDealAddress}`);
        this.getClaimingDealDTO();
      });

    this.eventBus.loginEvent.subscribe(result => {
      console.log('loginEvent subscription:' + result);
      this.eventLogin(result);
    });

    this.eventBus.logoutEvent.subscribe(result => {
      console.log('logoutEvent subscription:' + result);
      this.eventLogout();
    });

    this.account = this.userSessionProvider.linkedWallet;
    this.updateDealContractData();

  }

  getClaimingDealDTO(): void {
    this.claimingDealServiceProxy.getByAddress(this.claimingDealAddress).subscribe(result => {
        this.claimingDeal = result;
        this.name =  this.claimingDeal.name;
        this.comment =  this.claimingDeal.comment;
        this.totalTokens = this.claimingDeal.totalTokens;

        this.getClaimingDealTransferEvents();
        this.getClaimingDealUserProofs();
        console.log("chainid="+this.web3Service.chainIdNumber)
        this.dealService.getAll()
          .subscribe(result => {
              this.allDeals = result;
              this.filterDealsByTargetNetwork(this.web3Service.chainIdNumber, this.allDeals);
              this.updateDealFormValues(this.claimingDeal.dealAddress);
            },
            error => {
              this.processServiceError(error);
            });

        console.log(this.claimingDeal);
        if (this.web3Service.chainIdNumber != this.claimingDeal.chainId) {
          let chainName = new NetworkNamePipe().transform(this.claimingDeal.chainId);
          this.showErrorModal(`Select ${chainName} network in metamask!`);
          this.router.navigate(["/claiming"]);
        }
      },
      error => {
        this.processServiceError(error);
      });
  }

  private getClaimingDealTransferEvents() {
    this.claimingDealServiceProxy.getTransfersByAddress(this.claimingDeal.address)
      .subscribe(result => {
          this.claimingTransferEvents = result;
        },
        error => {
          this.processServiceError(error);
        });
  }

  private getClaimingDealUserProofs() {
    this.claimingDealServiceProxy.userDetailsGetAll(this.claimingDeal.address,null,null)
      .subscribe(result => {
          this.claimingUserEvents = result;
          //this.filterByClaimingDeal(this.claimingDeal.address, this.claimingUserEvents);
        },
        error => {
          this.processServiceError(error);
        });
  }

  async updateDealContractData() {

    console.log(`Get contract data ${this.claimingDealAddress}`);
    this.rewardToken = await this.web3Service.getClaimingDealToken(this.claimingDealAddress);
    if (this.rewardToken != "0x0000000000000000000000000000000000000000") {
      this.web3Service.GetContractSymbol(this.rewardToken).then((resp) => {
        this.rewardTokenSymbol = resp;
      });
      this.rewardDecimal = parseInt(await this.web3Service.GetDecimals(this.rewardToken));
    } else {
      //this.paymentTokenSymbol = get from DTO
      this.rewardDecimal = 18;
    }

    this.isPaused = Boolean( await this.web3Service.isPausedClaimingDeal(this.claimingDealAddress));
    this.merkleRoot =  await this.web3Service.getClaimingDealMerkleRoot(this.claimingDealAddress);
    console.log('merkle='+this.merkleRoot);
  }
  async updateClaimingDealDb(): Promise<void> {
    this.waiting = true;

    var data: ClaimingDealUpdateDTO = new ClaimingDealUpdateDTO();
    data.claimingDealAddress = this.claimingDealAddress;
    data.dealAddress = this.deal?.dealAddress;
    data.name = this.name;
    data.comment = this.comment;

    console.log({data});

    this.claimingDealServiceProxy.update(data)
      .subscribe(
        result => {
          this.waiting = false;
          this.showInfoModal("Saved")
        },
        error => {
          this.waiting = false;
          this.processServiceError(error);
        }
      );
  }

  async updateLockClaimingDealClick(pause: boolean): Promise<void> {
    this.waiting = true;
    let contractEventsSource;
       if(pause) {
         contractEventsSource = this.web3Service.pauseClaimingDeal(this.account, this.claimingDealAddress);
       }else {
         contractEventsSource = this.web3Service.unPauseClaimingDeal(this.account, this.claimingDealAddress);
       }
    try {
      let response = await contractEventsSource.receipt$.toPromise();
      console.log(response);
      //dialogRef.close();
      this.showInfoModal('Confirmed transaction');
      this.updateDealContractData();
    } catch (err) {
      //dialogRef.close();
      console.info('catch');
      console.info(err);
    }
    this.waiting = false;
  }

  eventLogin(username: string): void {
    console.log('eventLogin');
    console.log(username);
    this.account = username;
  }

  eventLogout(): void {
    this.account = "";
  }

  processServiceError(error: any) {
    if (error.status == 401) {
      console.error('401');
      this.userSessionProvider.finishAuth();
      this.navigateToLogin();
    }
    else {
      console.error(error);
      this.showErrorModal(JSON.parse(error.response).message);
    }
  }

  navigateToLogin(): void {
    this.router.navigate(["/login"]);
  }

  recalculateClaimingDealClick() {
    this.waiting = true;
    this.claimingDealServiceProxy.recalculate(this.claimingDealAddress)
      .subscribe(
        result => {
          this.waiting = false;
          this.getClaimingDealDTO();
          this.updateDealContractData();
          this.showInfoModal("Recalculated")
        },
        error => {
          this.waiting = false;
          this.processServiceError(error);
        }
      );
  }

  updateDealFormValues(dealAddressChanged: any) {
    for (const deal of this.allDeals) {
      if (deal.dealAddress == dealAddressChanged) {
        this.deal = deal;
        console.log(deal);
      }
    }
  }

  async updateMerkleRoot(): Promise<void> {
    this.waiting = true;
    const contractEventsSource  = this.web3Service.updateClaimingDealMerkleRoot(this.account, this.claimingDealAddress, this.claimingDeal.merkleRoot);

    try {
      let response = await contractEventsSource.receipt$.toPromise();
      console.log(response);
      //dialogRef.close();
      this.showInfoModal('Confirmed transaction');
      this.updateDealContractData();
    } catch (err) {
      //dialogRef.close();
      console.info('catch');
      console.info(err);
    }
    this.waiting = false;
  }

  private filterByClaimingDeal(address: string, claimingTransferEvents: Array<ClaimingDealUserDetailsDTO>) {
    //TODO: workaround untill backend will provide API end point
    this.claimingUserEvents = claimingTransferEvents.filter(p => p.claimingDealAdress == address);
  }

  private filterDealsByTargetNetwork(chainIdNumber: number, allDeals: Array<DealDTO>) {
    this.allDeals = allDeals.filter(p =>p.targetNetwork == chainIdNumber);
  }
}
