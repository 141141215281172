<div class="modal-header">
  <h5 class="modal-title">Switch Network</h5>
</div>
<div class="__row modal-body">
  <div class="__row-currency"
       [ngClass]="{ '__row-currency--active': web3Service.chainIdNumber == 42 }"
       (click)="selectNetworkClick(42)">
    <img src="../../assets/svg/eth_black.svg" />
    <!--<span>Ethereum</span>-->
    <span>ETH</span>
  </div>
  <div class="__row-currency"
       [ngClass]="{ '__row-currency--active': web3Service.chainIdNumber == 97 }"
       (click)="selectNetworkClick(97)">
    <img src="../../assets/svg/bsc.svg" />
    <span>BSC</span>
  </div>
</div>
