import { Component, OnInit } from '@angular/core';
import {ComponentBase} from '../../shared/component-base';
import {EventBus} from '../../shared/event-bus';
import {Web3Service} from '../../shared/web3-service';
import {UserSessionProvider} from '../../shared/user-session-provider';
import {
  ClaimingDealDTO,
  ClaimingDealServiceProxy
} from '../../service-proxies/service-proxies';
import {Router} from '@angular/router';
import {BigNumber} from 'bignumber.js';
import { forkJoin, from, Observable, of, OperatorFunction, pipe } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';

export interface ClaimingDealItemView extends ClaimingDealDTO {
  isLocked?:boolean;
}

@Component({
  selector: 'claiming',
  templateUrl: './claiming.component.html',
  styleUrls: ['./claiming.component.scss']
})
export class ClaimingComponent extends ComponentBase implements OnInit {

  constructor(private eventBus: EventBus,
              private web3Service: Web3Service,
              private userSessionProvider: UserSessionProvider,
              private claimingDealService: ClaimingDealServiceProxy,
              private router: Router
  ) {
    super();
  }
  public allClaimingDeals: Array<ClaimingDealItemView> = [];
  public allClaimingDeals$: Observable<ClaimingDealItemView[]>;
  waitingDeals: boolean = true;


  async ngOnInit() {
    await this.web3Service.initWeb3();

    window.dispatchEvent(new Event('resize'));

    this.allClaimingDeals$ = this.claimingDealService.getByChainID(this.web3Service.chainIdNumber).pipe(
      this.getClaimingDeals()
    )

    this.claimingDealService.getByChainID(this.web3Service.chainIdNumber)
      .subscribe(result => {
          this.allClaimingDeals = result;
          console.log(this.allClaimingDeals);
          this.waitingDeals = false;
        },
        error => {
          this.processServiceError(error);
        });
  }

  processServiceError(error: any) {
    if (error.status == 401) {
      console.error('401');
      this.userSessionProvider.finishAuth();
      this.navigateToLogin();
    }
    else {
      console.error(error);
      this.showErrorModal(JSON.parse(error.response).message);
    }
  }

  navigateToLogin(): void {
    this.router.navigate(["/login"]);
  }

  public navigateToEditClaimingDeal(claimingDealAddress: string) {
    this.router.navigate(['/edit-claiming-deal'], { queryParams: { address: claimingDealAddress } });
  }

  public navigateToEditDeal(dealAddress: string) {
    this.router.navigate(['/edit-deal'], { queryParams: { address: dealAddress } });
  }

  getClaimedPercent(claimedAmount: string | undefined, totalTokens: string | undefined): number {
    if (totalTokens && claimedAmount && totalTokens != '0')
      return (new BigNumber(claimedAmount).div(new BigNumber(totalTokens)).multipliedBy( 100)).toNumber();
    return 0;
  }

  private getClaimingDeals(): OperatorFunction<ClaimingDealItemView[], ClaimingDealItemView[]> {
    return pipe(
      concatMap((claimingDeals: ClaimingDealItemView[]) => {
        return claimingDeals.length ? forkJoin(claimingDeals.map(claimingDeal => this.checkAddress(claimingDeal))) : of([])
      }),
    );
  }

  private checkAddress(claimingDeal: ClaimingDealItemView): Observable<ClaimingDealItemView> {
    return from(this.web3Service.isPausedClaimingDeal(claimingDeal.address)).pipe(map(claimingInfo => {
      claimingDeal.isLocked = !!claimingInfo;
      return claimingDeal;
    }));
  }

}

