import { Component, OnInit } from '@angular/core';
import { ComponentBase } from '../../shared/component-base';
import { UserDTO, UsersServiceProxy } from '../../service-proxies/service-proxies';
import { AlertService } from '../shared-dlg.module';
import { UserSessionProvider } from '../../shared/user-session-provider';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import {MatSort, Sort, SortDirection} from '@angular/material/sort';

@Component({
  selector: 'users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent extends ComponentBase implements OnInit {
  public users: Array<UserDTO> = [];
  waiting: boolean = false;
  account: string = '';
  public totalCount: number | null = null;
  private _pageSize: number = 25;
  public page: number = 0;
  public sortByColumn: string = '';
  public sortDirection: SortDirection = '';
  loading: boolean = false;
  snapshotLoading: boolean = false;
  lastSearch: string = null;
  tierIndex: number = null;
  tiers: number[] = [0, 1, 2];

  public get pageSize(): number {
    const savedSize = localStorage.getItem('users_pageSize');
    if (savedSize){
      this._pageSize = +savedSize;
    }
    return this._pageSize;
  }

  public set pageSize(value){
    this._pageSize = value;
    localStorage.setItem('users_pageSize', value.toString());
  }

  public async saveData(): Promise<void> {
    const allData = await this.usersServiceProxy.searchUsers(this.searchString, null, null, null, null, this.tierIndex === -1 ? null : this.tierIndex).toPromise();
    const csvString = this.convertObjectToCSV(allData.items);
    const blob = new Blob([csvString], {type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'user_search.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  private convertObjectToCSV(objArray: any): string {
    const replacer = (key: any, value: any) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(objArray[0]);
    const csv = [
      header.join(','), // header row first
      ...objArray.map((row: any) => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
    ].join('\r\n');
    return csv;
  }

  constructor(
    private userSessionProvider: UserSessionProvider,
    private usersServiceProxy: UsersServiceProxy,
    private router: Router
  ) {
    super();
  }
  //https://cockpit.sumsub.com/checkus#/applicant/60bce8ed8a9a0400018c7f74/basicInfo?clientId=bullperks
  searchString: string;
  public getSumSupURL(applicantId: string): string {
    return `${environment.sumsupApplicantUrl}${applicantId}/basicInfo?clientId=${environment.sumsupClientId}`;
  }

  async ngOnInit() {
    this.loading = true;
    this.usersServiceProxy.searchUsers(null, this.pageSize, this.page, this.sortDirection, this.sortByColumn)
      .subscribe(result => {
        this.totalCount = result.totalItems;
        this.users = result.items;
      },
        error => {
          this.processServiceError(error);
        }, () => {
          this.loading = false;
        });
  }

  processServiceError(error: any) {
    if (error.status == 401) {
      console.error('401');
      this.userSessionProvider.finishAuth();
      this.navigateToLogin();
    }
    else
      this.showErrorModal(error.message || JSON.parse(error.response).message);
  }

  searchClick() {
    this.loading = true;

    if (this.searchString != this.lastSearch) {
      this.page = 0;
    }

    this.lastSearch = this.searchString;

    this.usersServiceProxy.searchUsers(this.searchString, this.pageSize, this.page, this.sortDirection, this.sortByColumn, this.tierIndex === -1 ? null : this.tierIndex)
      .subscribe(result => {
          this.totalCount = result.totalItems;
          this.users = result.items;
        },
        error => {
          this.processServiceError(error);
        },
        () => {
          this.loading = false;
        });
  }

  snapshotClick() {
    this.snapshotLoading = true;
    this.usersServiceProxy.getSnapshot().subscribe(
      data => {
        let blob = new Blob([data], {type: 'text/csv'});
        let downloadURL = window.URL.createObjectURL(blob);
        let link = document.createElement('a');

        link.href = downloadURL;
        link.download = "snapshot.csv";
        link.click();
      },
      error => {
        this.processServiceError(error);
      }
    ).add(() => this.snapshotLoading = false)
  }

  syncKYCClick(user: UserDTO) {
    this.usersServiceProxy.syncKYC(user.ethAddress)
      .subscribe(
        result => {
          this.waiting = false;

          if (result.applicantID) {
            this.showInfoModal(`Synced. Applicant id: ${result.applicantID}. Approved: ${result.kycConfirmed}`);
            user.kycConfirmed = result.kycConfirmed;
            user.sumAndSupApplicantId = result.applicantID;
          }
          else {
            this.showInfoModal(`Applicant not found`);
          }
        },
        error => {
          this.waiting = false;
          this.processServiceError(error);
        }
      );
  }

  navigateToLogin(): void {
    this.router.navigate(["/login"]);
  }

  sortData(sort: Sort) {
    const data = this.users.slice();
    if (!sort.active || sort.direction === '') {
      this.sortByColumn = '';
      this.sortDirection = '';
    } else {
      this.sortByColumn = sort.active;
      this.sortDirection = sort.direction;
    }
    this.searchClick();
  }

  public navigateToUserDetails(userAddress: string) {
    this.router.navigate(['/user-details'], { queryParams: { userid: userAddress } });
  }

  pageChanged($event: any): void {
    this.page = $event.pageIndex;
    this.pageSize = $event.pageSize;
    this.searchClick();
  }
}
