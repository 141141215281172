import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ComponentBase } from '../../shared/component-base';
import { EventBus } from '../../shared/event-bus';
import { Web3Service } from '../../shared/web3-service';
import { DlgContractService } from '../dlg-contract.service';

import { AlertService } from '../shared-dlg.module';
import { UserSessionProvider } from '../../shared/user-session-provider';

@Component({
  selector: 'main-page',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainPageComponent extends ComponentBase implements OnInit {
  constructor(
    private _dialog: MatDialog,
    private _dlgContractSrv: DlgContractService,
    private _alertSrv: AlertService,
    private eventBus: EventBus,
    private web3Service: Web3Service,
    private userSessionProvider: UserSessionProvider
  ) {
    super();
  }

  waiting: boolean = false;
  account: string = '';

  async ngOnInit() {
    await this.web3Service.initWeb3();
    //this.eventBus.loginEvent.subscribe(result => {
    //  console.log('loginEvent subscription:' + result);
    //  this.eventLogin(result);
    //});

    //this.eventBus.logoutEvent.subscribe(result => {
    //  console.log('logoutEvent subscription:' + result);
    //  this.eventLogout();
    //});

    //this.eventBus.outputNetworkChanged.subscribe(result => {
    //  console.log('outputNetworkChanged subscription:' + result);
    //  this.setToNetworkAndPairTo(result);
    //});

    //this.eventBus.fromPairChanged.subscribe(result => {
    //  console.log('fromPairChanged subscription:' + result);
    //  this.setPairFrom(result);
    //});
  }
}
