import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {Observable} from "rxjs";
import {BrowserStorageProvider} from "../shared/browser-storage-provider";


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authHeader = this.getAuthHeader();
    if (authHeader){
      req = req.clone({
        setHeaders: {
          'Authorization': authHeader
        },
      });
    }

    return next.handle(req);
  }

  private getAuthHeader():any{
    let injector = Injector.create({ providers: [{ provide: BrowserStorageProvider, deps: [] }] });
    let browserStorage: BrowserStorageProvider = injector.get(BrowserStorageProvider);
    let storage = browserStorage.getStorage(localStorage);
    if (storage.getItem("authToken") != undefined)
      return "Bearer " + storage.getItem("authToken");
    return null;
  }
}
