import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'networkSymbol',
})
export class NetworkSymbolPipe implements PipeTransform {
  transform(value: number): string {
    if (value == 1 || value == 42) return 'ETH';
    else if (value == 56 || value == 97) return 'BSC';
    else if (value == 128 || value == 256) return 'HT';
    return value.toString();
  }
}
