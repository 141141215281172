import { Component, OnInit } from '@angular/core';
import { EventBus } from '../../shared/event-bus';
import { Web3Service } from '../../shared/web3-service';
import { ComponentBase } from '../../shared/component-base';
import Web3 from 'web3';
import { Router } from '@angular/router';
import { UserSessionProvider } from '../../shared/user-session-provider';
import { environment } from '../../environments/environment';

@Component({
  templateUrl: './create-pool.component.html',
  styleUrls: ['./create-pool.component.scss']
})
export class CreatePoolComponent extends ComponentBase implements OnInit {
  feeTokenApproved: boolean;
  rewardTokenApproved: boolean;
  rewardDecimal: number;
  maxDistributedTokenAmount: number;
  constructor(
    private eventBus: EventBus,
    private web3Service: Web3Service,
    private _router: Router,
    private userSessionProvider: UserSessionProvider
  ) {
    super();
  }

  step: number = 1;
  web3ChainId: number;
  account: string;
  balance: number = 0;
  waiting: boolean = false;

  poolName: string;
  poolDescription: string;
  startTimestamp: number;
  finishTimestamp: number;
  hasWhiteListing: boolean = false;
  poolTokenAmount: number;
  depositFeeBP: number;
  feeTo: string;

  feeAmount: number;
  feeToken: string;

  stagingToken: string;
  poolToken: string;

  timerId: NodeJS.Timeout;

  async ngOnInit() {
    this.eventBus.loginEvent.subscribe(result => {
      console.log('loginEvent subscription:' + result);
      this.eventLogin(result);
      this.web3Service.getEthBalance(this.account).then((value) => {
        this.balance = this.toNumberFromWeiFixed(value, 18);
      });
    });

    this.eventBus.logoutEvent.subscribe(result => {
      console.log('logoutEvent subscription:' + result);
      this.eventLogout();
    });

    await this.web3Service.initWeb3();
    if (this.userSessionProvider.linkedWallet) {
      this.eventLogin(this.userSessionProvider.linkedWallet);
    }
    this.web3ChainId = this.web3Service.chainIdNumber;
    await this.getFeeAmount();
  }

  async ngOnDestroy() {
    if (this.timerId) {
      clearInterval(this.timerId);
    }
  }

  eventLogin(username: string): void {
    console.log('eventLogin');
    console.log(username);
    if (this.account != username) {
      this.account = username;
      this.updateBalanceData();

      this.timerId = setInterval(() => {
        this.updateBalanceData()
      }, this.expectedBlockTime);
    }
  }

  eventLogout(): void {
    this.account = "";
    this.balance = null;
    console.log('eventLogout')
    if (this.timerId) {
      console.log('clearInterval');
      clearInterval(this.timerId);
    }
  }

  async updateBalanceData() {
    console.log('updateBalanceData');

    //this.web3Service.GetTokenBalance(this.account, this.feeTokenAddress).then((balance) => {
    //  this.balance = this.toNumberFromWei(balance, 18);
    //});
    //this.web3Service.GetAllowance(this.account, this.feeTokenAddress, this.idoCreatorAddress).then((resp) => {
    //  this.allowanceBCS = this.toNumberFromWei(resp, 18);
    //});
  }

  toStep1(): void {
    this.step = 1;
    this.rewardTokenApproved = false;
    this.feeTokenApproved = false;
  }

  async confirmForm(): Promise<void> {
    await this.getFeeAmount();
    if(this.feeAmount > 0) {
      this.step = 2;
    }
    else {
      this.step = 3;
    }
    
  }

  async approveRewardTokenClick(): Promise<void> {
    this.waiting = true;

    //const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

    const contractEventsSource = this.web3Service.approve(
      this.account,
      this.poolToken,
      this.web3Service.stakingPoolMasterAddress
    );

    try {
      await contractEventsSource.receipt$.toPromise();

      //dialogRef.close();
      //this._alertSrv.show('Confirmed transaction');
      this.rewardTokenApproved = true;
      this.showInfoModal('Confirmed transaction');
      this.step = 4;
    } catch (err) {
      //dialogRef.close();
      console.info('catch');
      console.info(err);
    }

    this.waiting = false;
  }

  async getFeeAmount(): Promise<void> {
    this.feeAmount = (await this.web3Service.GetStakeMasterFeeAmount()) / (10 ** 18);
    console.log(this.feeAmount);
  }

  async getFeeToken(): Promise<void> {
    this.feeToken = await this.web3Service.GetStakeMasterFeeToken();
    console.log(this.feeToken);
  }

  async approveFeeTokenClick() {
    this.waiting = true;
    this.rewardDecimal = parseInt(await this.web3Service.GetDecimals(this.poolToken));
    await this.getFeeAmount();
    if(this.feeAmount > 0) {
      console.log("feeAmount > 0; get feeToken");
      await this.getFeeToken();
      if(this.feeToken != "0x0000000000000000000000000000000000000000") {
        console.log("feeToken != address(0); approve feeToken");
        const contractEventsSource = this.web3Service.approve(
          this.account,
          this.feeToken,
          this.web3Service.stakingPoolMasterAddress
        );

        try {
          await contractEventsSource.receipt$.toPromise();
          this.feeTokenApproved = true;
          this.step = 3;
          //dialogRef.close();
          //this._alertSrv.show('Confirmed transaction');
          this.showInfoModal('Confirmed transaction');
        } catch (err) {
          //dialogRef.close();
          console.info('catch');
          console.info(err);
        }
      }
    }
    this.waiting = false;
  }


  // createStakingPool(userAddress: string, stakingToken: string, poolToken: string, startTime: number, finishTime: number, poolTokenAmount: number, hasWhiteListing: boolean,
  //   depositFeeBP: number, feeTo: string)

  async createStakingPoolClick() {
    this.waiting = true;
    this.feeTo = this.account;

    let msgValue = (this.feeAmount > 0 && this.feeToken == "0x0000000000000000000000000000000000000000") ? this.feeAmount : 0;
    let poolTokenDecimals = await this.web3Service.GetDecimals(this.poolToken);

    console.log("msg.value = " + msgValue);
    console.log("account = " + this.account);
    console.log("stagingToken = " + this.stagingToken);
    console.log("poolToken = " + this.poolToken);
    console.log("startTimestamp = " + this.startTimestamp);
    console.log("finishTimestamp = " + this.finishTimestamp);
    console.log("poolTokenAmount = " + this.poolTokenAmount);
    console.log("hasWhiteListing = " + this.hasWhiteListing);
    console.log("depositFeeBP = " + this.depositFeeBP);
    console.log("feeTo = " + this.feeTo);
    const contractEventsSource = this.web3Service.createStakingPenaltyPool(this.account, this.stagingToken, this.poolToken, this.startTimestamp, this.finishTimestamp, this.poolTokenAmount, this.hasWhiteListing,
      this.depositFeeBP, this.feeTo, msgValue);

      try {
        let response = await contractEventsSource.receipt$.toPromise();
        console.log(response);
        this.showInfoModal('Confirmed transaction');

        console.log(response.events.StakingPenaltyPoolCreated);
        this._router.navigate(['/edit-pool'], { queryParams: { address: response.events.StakingPenaltyPoolCreated.returnValues.pool } });

      } catch (err) {
        console.info('catch');
        console.info(err);
      }

      this.waiting = false;
  }

}

