import { Component, OnInit } from '@angular/core';
import { ComponentBase } from '../../shared/component-base';
import { AlertService } from '../shared-dlg.module';
import { AccountServiceProxy, AuthenticateInputDTO } from '../../service-proxies/service-proxies';
import { ActivatedRoute, Router } from '@angular/router';
import { EventBus } from '../../shared/event-bus';
import { Web3Service } from '../../shared/web3-service';
import { MatDialog } from '@angular/material/dialog';
import { DlgUnlockWalletComponent } from '../dlg-unlock-wallet';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { UserSessionProvider } from '../../shared/user-session-provider';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginPageComponent extends ComponentBase implements OnInit {
  constructor(
    private userSessionProvider: UserSessionProvider,
    private _alertSrv: AlertService,
    private accountService: AccountServiceProxy,
    private route: ActivatedRoute,
    private router: Router,
    private eventBus: EventBus,
    private web3Service: Web3Service,
    private _dialog: MatDialog
  ) {
    super();
  }

  waiting: boolean = false;
  account: string = '';
  signUpEmail: string = '';
  step: number = 1;


  async ngOnInit() {
    await this.web3Service.initWeb3();
    this.eventBus.loginEvent.subscribe(result => {
      console.log('loginEvent subscription:' + result);
      this.eventLogin(result);
    });

    this.accountService.checkAuth()
      .subscribe(result => {
        this.router.navigate(["/deals"]);
      },
        error => {
          console.error(error);
        });
  }

  async eventLogin(username: string) {
    console.log('eventLogin');
    console.log(username);
    this.account = username;
    this.step = 2;
  }

  async unlockWallet() {
    const dialogRef = this._dialog.open(DlgUnlockWalletComponent, {
      panelClass: ['dlg-small', 'dlg-light'],
      scrollStrategy: new NoopScrollStrategy(),
    });
  }

  async loginClick() {
    if (!this.account) {
      this.unlockWallet();
      return;
    }
    var data: AuthenticateInputDTO = new AuthenticateInputDTO();
    data.timeStamp = Math.floor(Date.now() / 1000);
    data.signature = await this.web3Service.PersonalSign(data.timeStamp.toString(), this.account);
    if (!data.signature) {
      console.log("Not signed!");
      return;
    }
    data.ethAddress = this.account;
    this.waiting = true;

    this.accountService.authenticate(data)
      .subscribe(
        result => {
          this.waiting = false;
          //TODO: add eth address in DTO
          this.userSessionProvider.startAuth(this.account, result.userName, result.accessToken, result.isSuperAdmin);
          this.router.navigate([result.isSuperAdmin ? "/deals" : "/users"]);
        },
        error => {
          this.waiting = false;
          //if (JSON.parse(error.response).message != 'User not found!') {
          if (JSON.parse(error.response).message){
            this.showErrorModal(JSON.parse(error.response).message);
          }
          else {
            this.showErrorModal(error.response);
          }
          //}
          //else {
          //  this._alertSrv.show('Process registration first', 'information');
          //  this.router.navigate(["/signup"]);
          //}
          //this.showErrorModal(JSON.parse(error.response).message);
        }
      );
  }
}
