import { Component, OnInit } from '@angular/core';
import {ComponentBase} from '../../shared/component-base';
import {EventBus} from '../../shared/event-bus';
import {Web3Service} from '../../shared/web3-service';
import {Router} from '@angular/router';
import {UserSessionProvider} from '../../shared/user-session-provider';

@Component({
  templateUrl: './create-claiming-deal.component.html',
  styleUrls: ['./create-claiming-deal.component.scss']
})
export class CreateClaimingDealComponent extends ComponentBase implements OnInit {

  constructor(
    private eventBus: EventBus,
    private web3Service: Web3Service,
    private _router: Router,
    private userSessionProvider: UserSessionProvider
  ) {
    super();
  }
  web3ChainId: number;
  waiting: boolean = false;
  balance: number = 0;
  timerId: NodeJS.Timeout;
  account: string;
  rewardToken: string;

  async ngOnInit() {
    this.eventBus.loginEvent.subscribe(result => {
      console.log('loginEvent subscription:' + result);
      this.eventLogin(result);
    });

    this.eventBus.logoutEvent.subscribe(result => {
      console.log('logoutEvent subscription:' + result);
      this.eventLogout();
    });

    await this.web3Service.initWeb3();
    if (this.userSessionProvider.linkedWallet) {
      this.eventLogin(this.userSessionProvider.linkedWallet);
    }
    this.web3ChainId = this.web3Service.chainIdNumber;
  }

  eventLogin(username: string): void {
    console.log('eventLogin');
    console.log(username);
    if (this.account != username) {
      this.account = username;
      this.updateBalanceData();

      this.timerId = setInterval(() => {
        this.updateBalanceData()
      }, this.expectedBlockTime);
    }
  }

  eventLogout(): void {
    this.account = "";
    this.balance = null;
    console.log('eventLogout')
    if (this.timerId) {
      console.log('clearInterval');
      clearInterval(this.timerId);
    }
  }

  async updateBalanceData() {
    console.log('updateBalanceData');

    //this.web3Service.GetTokenBalance(this.account, this.feeTokenAddress).then((balance) => {
    //  this.balance = this.toNumberFromWei(balance, 18);
    //});
    //this.web3Service.GetAllowance(this.account, this.feeTokenAddress, this.idoCreatorAddress).then((resp) => {
    //  this.allowanceBCS = this.toNumberFromWei(resp, 18);
    //});
  }

  async createClaimingDealClick() {
    if (!this.rewardToken) {
      this.showWarningModal("Need to set token address");
      return;
    }
    if (!this.web3Service.web3.utils.isAddress(this.rewardToken)!) {
      this.showWarningModal("Not valid token address");
      return;
    }
    this.waiting = true;
//    let msgValue = (this.feeAmount > 0 && this.feeToken == "0x0000000000000000000000000000000000000000") ? this.feeAmount : 0;

    console.log('rewardToken='+this.rewardToken);

    const contractEventsSource = this.web3Service.createMerkleDistributor(this.account, this.rewardToken);

    try {
      let response = await contractEventsSource.receipt$.toPromise();
      console.log(response);
      this.showInfoModal('Confirmed transaction');

      console.log(response.events.DistributorCreated);
      this._router.navigate(['/edit-claiming-deal'], { queryParams: { address: response.events.DistributorCreated.returnValues.distributor } });

    } catch (err) {
      console.info('catch');
      console.info(err);
    }

    this.waiting = false;
  }
}

