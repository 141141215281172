<main class="dashboard-main">
  <section class="__create-form">

    <div class="white-card p-4 mb-40">
      <label>Token</label>

      <mat-form-field appearance="fill">
        <mat-label>Token Address</mat-label>
        <input matInput name="rewardToken"
               [(ngModel)]="rewardToken"
               required>
      </mat-form-field>
    </div>
  </section>

  <section class="__submit mt-auto bg-white">
    <button mat-raised-button color="primary" class="btn-icon btn-icon-left w-100"
            (click)="createClaimingDealClick()"
            [disabled]="waiting">
      <i class="icon icomoon-plus"></i>
      Create Claiming Deal
    </button>
  </section>
</main>
