import { Component, OnInit } from '@angular/core';
import { ComponentBase } from '../../shared/component-base';
import { AdminServiceProxy, AdminDTO } from '../../service-proxies/service-proxies';
import { AlertService } from '../shared-dlg.module';
import { UserSessionProvider } from '../../shared/user-session-provider';
import { Router } from '@angular/router';

@Component({
  selector: 'admins',
  templateUrl: './admins.component.html',
  styleUrls: ['./admins.component.scss'],
})
export class AdminsComponent extends ComponentBase implements OnInit {
  constructor(
    private userSessionProvider: UserSessionProvider,
    private adminServiceProxy: AdminServiceProxy,
    private router: Router
  ) {
    super();
  }

  public users: Array<AdminDTO> = [];
  waiting: boolean = false;
  account: string = '';

  async ngOnInit() {

    this.adminServiceProxy.getAll()
      .subscribe(result => {
        this.users = result;
      },
        error => {
          this.processServiceError(error);
        });
  }

  processServiceError(error: any) {
    if (error.status == 401) {
      console.error('401');
      this.userSessionProvider.finishAuth();
      this.navigateToLogin();
    }
    else
      this.showErrorModal(JSON.parse(error.response).message);
  }

  navigateToLogin(): void {
    this.router.navigate(["/login"]);
  }
}
