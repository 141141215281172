import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "imgTierPipe"
})
export class ImgTierPipe implements PipeTransform {
  transform(index: number): string {
    if (index == -1)
      return "/assets/images/tier/flat/none.svg";
    if (index == 0) return '/assets/images/tier/flat/Starter.svg';
    if (index == 1) return '/assets/images/tier/flat/Builder.svg';
    if (index == 2) return '/assets/images/tier/flat/Angel.svg';
    return "/assets/images/tier/flat/none.svg";
  }
}
