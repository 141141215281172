<main class="dashboard-main">
  <section class="dashboard-section p-40">
    <div class="white-card p-4 sign-up-form text-center" *ngIf="step==1">
      <div class="subtitle-1 mb-4">Step 1</div>

      <i class="icomoon-lock __icon"></i>
      <div class="small-text my-3 mute-80">Unlock your wallet</div>

      <button type="button"
              class="w-100"
              mat-raised-button
              color="primary"
              (click)="unlockWallet()"
              [ngClass]="{'btn-loading': waiting}"
              [disabled]="waiting">
        Unlock wallet
      </button>
    </div>

    <div class="white-card p-4 sign-up-form text-center" *ngIf="step==2">
      <div class="subtitle-1 mb-4">Step 2</div>
      <i class="icomoon-unlock-2 __icon"></i>
      <div class="small-text my-3 mute-80">Sign message by Metamask and login</div>

      <button type="button"
              class="w-100"
              mat-raised-button
              color="primary"
              (click)="loginClick()"
              [ngClass]="{'wait': waiting}"
              [disabled]="waiting">
        Login
      </button>
    </div>
  </section>
</main>
